import React, { useMemo, useState } from 'react';

import {
  GoogleAdsPerformanceModalData,
  GoogleAdsPerformanceModalConfig,
  ModalMode,
} from './InterfaceType';
import { useDispatch } from 'react-redux';
import CustomModal from './CustomModal';
import { Alert, Button, TextField, Autocomplete } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  createGAPRecords,
  deleteGAPRecordsById,
  updateGAPRecordByCampaignId,
} from '../store/googleAdsPerformanceSlice';
import {
  createAdsPerformance,
  deleteGAPById,
  updateGAPById,
} from '../apis/adsPerformanceApis';
import CircularProgress from '@mui/material/CircularProgress';
import PercentIcon from '@mui/icons-material/Percent';

interface StaticOption {
  label: string;
}

const campaignTypesOptions: StaticOption[] = [
  { label: 'search' },
  { label: 'display' },
  { label: 'video' },
  { label: 'shopping' },
  { label: 'app' },
  { label: 'local' },
  { label: 'smart' },
  { label: 'performance' },
  { label: 'max' },
];

const GoogleAdsPerformanceModal: React.FC<GoogleAdsPerformanceModalConfig> = ({
  mode,
  setIsOpen,
  googleAdsPerformanceData,
}) => {
  const [newGAPData, setNewGAPData] = useState<GoogleAdsPerformanceModalData>(
    googleAdsPerformanceData,
  );
  const [isSubmittedOnce, setIsSubmittedOnce] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const dispatch = useDispatch();

  const isFieldNonEmpty = (obj: GoogleAdsPerformanceModalData) => {
    if (mode === 'CREATE_GAP' || mode === 'EDIT_GAP')
      return Object.entries(obj).every(([key, value]) => {
        console.log(typeof value);
        if (typeof value === 'number') {
          return !isNaN(value);
        } else if (typeof value === 'object' || typeof value === 'string') {
          return value !== null && value !== undefined && value !== '';
        }
        return true;
      });
    else {
      return true;
    }
  };

  const buttonText = useMemo(() => {
    if (mode === 'CREATE_GAP') return 'Create';
    else if (mode === 'EDIT_GAP') return 'Save';
    else if (mode === 'DELETE_GAP') return 'Confirm';
    return '';
  }, [mode]);

  const titleText = useMemo(() => {
    if (mode === 'CREATE_GAP') return 'New Record';
    else if (mode === 'EDIT_GAP') return 'Edit Record';
    else if (mode === 'DELETE_GAP') return 'Delete Record';
    return '';
  }, [mode]);

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): Promise<void> => {
    e.preventDefault();
    setIsSubmittedOnce(true);
    setAlertMessage('');

    if (!isFieldNonEmpty(newGAPData) || isNaN(Number(newGAPData.date))) {
      setAlertMessage('Please fill all required fields');
      return;
    }

    try {
      setIsLoading(true);
      switch (mode) {
        case ModalMode.CreateGAP:
          let createdRecord = await createAdsPerformance(newGAPData);
          dispatch(createGAPRecords({ ...createdRecord }));

          break;
        case ModalMode.EditGAP:
          let updatedRecord = await updateGAPById(newGAPData);
          dispatch(updateGAPRecordByCampaignId({ ...updatedRecord }));
          break;
        case ModalMode.DeleteGAP:
          await deleteGAPById(newGAPData);
          dispatch(deleteGAPRecordsById({ id: newGAPData.id }));
          setIsOpen(false);

          break;
        default:
          break;
      }
      setIsLoading(false);
      setIsOpen(false);
    } catch (err: any) {
      setIsLoading(false);
      handleSetAlertMessage(err);
    }
  };

  const handleSetAlertMessage = (err: any) => {
    if (Array.isArray(err.response.data.message))
      setAlertMessage(err.response.data.message[0]);
    else {
      setAlertMessage(err.response.data.message);
    }
  };

  return (
    <CustomModal
      top={titleText}
      middle={
        <>
          {mode === 'EDIT_GAP' || mode === 'CREATE_GAP' ? (
            <>
              <TextField
                required
                id="campaignId"
                label="Campaign ID"
                value={newGAPData?.campaignId || ''}
                onChange={(e) => {
                  setNewGAPData((data) => ({
                    ...data,
                    campaignId: e.target.value,
                  }));
                }}
                error={newGAPData.campaignId === null && isSubmittedOnce}
              />
              <Autocomplete
                id="campaign-type"
                options={campaignTypesOptions}
                isOptionEqualToValue={(option, value) =>
                  option && value && option.label === value.label
                }
                onChange={(e, option) => {
                  setNewGAPData((data) => ({
                    ...data,
                    campaignType: option && option.label,
                  }));
                }}
                defaultValue={
                  googleAdsPerformanceData.campaignType
                    ? {
                        label: googleAdsPerformanceData.campaignType,
                      }
                    : undefined
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Campaign Type"
                    error={newGAPData.campaignType === null && isSubmittedOnce}
                  />
                )}
              />
              <TextField
                required
                type="text"
                id="product"
                label="Product"
                value={newGAPData?.product || ''}
                onChange={(e) => {
                  setNewGAPData((data) => ({
                    ...data,
                    product: e.target.value || null,
                  }));
                }}
                error={newGAPData.product === null && isSubmittedOnce}
                helperText="Please put the value according to the naming convention: [YYYYMM]_[Product]"
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date"
                  value={newGAPData!.date}
                  inputFormat="yyyy-MM-dd"
                  onChange={(newValue) => {
                    setNewGAPData((data) => ({
                      ...data,
                      date: newValue,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      helperText={
                        (newGAPData.date === null ||
                          isNaN(Number(newGAPData.date))) &&
                        isSubmittedOnce
                          ? 'Invalid date'
                          : null
                      }
                      error={
                        (newGAPData.date === null ||
                          isNaN(Number(newGAPData.date))) &&
                        isSubmittedOnce
                      }
                    />
                  )}
                />
              </LocalizationProvider>
              <TextField
                required
                type="text"
                id="conversionType"
                label="Conversion Type"
                value={newGAPData?.conversionType || ''}
                onChange={(e) => {
                  setNewGAPData((data) => ({
                    ...data,
                    conversionType: e.target.value || null,
                  }));
                }}
                error={newGAPData.conversionType === null && isSubmittedOnce}
              />
              <TextField
                required
                type="text"
                id="adGroupId"
                label="Ad Group Id"
                value={newGAPData?.adGroupId || ''}
                onChange={(e) => {
                  setNewGAPData((data) => ({
                    ...data,
                    adGroupId: e.target.value || null,
                  }));
                }}
                error={
                  (newGAPData.adGroupId === null ||
                    newGAPData.adGroupId === '') &&
                  isSubmittedOnce
                }
              />
              <TextField
                required
                type="text"
                id="adGroupName"
                label="Ad Group Name"
                value={newGAPData?.adGroupName || ''}
                onChange={(e) => {
                  setNewGAPData((data) => ({
                    ...data,
                    adGroupName: e.target.value || null,
                  }));
                }}
                error={
                  (newGAPData.adGroupName === null ||
                    newGAPData.adGroupName === '') &&
                  isSubmittedOnce
                }
              />
              <TextField
                required
                type="number"
                id="conversions"
                label="Conversions"
                value={
                  Number(newGAPData?.conversions) >= 0
                    ? Number(newGAPData?.conversions)
                    : ''
                }
                onChange={(e) => {
                  setNewGAPData((data) => ({
                    ...data,
                    conversions: parseInt(e.target.value),
                  }));
                }}
                error={isNaN(newGAPData.conversions) && isSubmittedOnce}
              />
              <TextField
                required
                type="number"
                id="impressions"
                label="Impressions"
                value={
                  Number(newGAPData?.impressions) >= 0
                    ? Number(newGAPData?.impressions)
                    : ''
                }
                onChange={(e) => {
                  setNewGAPData((data) => ({
                    ...data,
                    impressions: parseInt(e.target.value),
                  }));
                }}
                error={isNaN(newGAPData.impressions) && isSubmittedOnce}
              />
              <TextField
                required
                type="number"
                id="clicks"
                label="Clicks"
                value={
                  Number(newGAPData?.clicks) >= 0
                    ? Number(newGAPData?.clicks)
                    : ''
                }
                onChange={(e) => {
                  setNewGAPData((data) => ({
                    ...data,
                    clicks: parseInt(e.target.value),
                  }));
                }}
                error={isNaN(newGAPData.clicks) && isSubmittedOnce}
              />
              <TextField
                required
                type="number"
                id="videoViews"
                label="Video Views"
                value={
                  Number(newGAPData?.videoViews) >= 0
                    ? Number(newGAPData?.videoViews)
                    : ''
                }
                onChange={(e) => {
                  setNewGAPData((data) => ({
                    ...data,
                    videoViews: parseInt(e.target.value),
                  }));
                }}
                error={isNaN(newGAPData.videoViews) && isSubmittedOnce}
              />
              <TextField
                required
                type="number"
                id="viewThroughConversions"
                label="View-through Conversions"
                value={
                  Number(newGAPData?.viewThroughConversions) >= 0
                    ? Number(newGAPData?.viewThroughConversions)
                    : ''
                }
                onChange={(e) => {
                  setNewGAPData((data) => ({
                    ...data,
                    viewThroughConversions: parseInt(e.target.value),
                  }));
                }}
                error={
                  isNaN(newGAPData.viewThroughConversions) && isSubmittedOnce
                }
              />
              <TextField
                required
                type="number"
                id="videoPlayedTo100Percentage"
                label="Video Played to 100%"
                value={
                  Number(newGAPData?.videoPlayedTo100Percentage) >= 0
                    ? Number(newGAPData?.videoPlayedTo100Percentage)
                    : ''
                }
                onChange={(e) => {
                  setNewGAPData((data) => ({
                    ...data,
                    videoPlayedTo100Percentage: parseFloat(e.target.value),
                  }));
                }}
                InputProps={{
                  endAdornment: <PercentIcon style={{ opacity: '0.54' }} />,
                }}
                helperText={
                  (isNaN(newGAPData.videoPlayedTo100Percentage) ||
                    newGAPData.videoPlayedTo100Percentage < 0) &&
                  'Must be non-negative'
                }
                error={
                  (isNaN(newGAPData.videoPlayedTo100Percentage) ||
                    newGAPData.videoPlayedTo100Percentage < 0) &&
                  isSubmittedOnce
                }
              />
              <TextField
                required
                type="number"
                id="installs"
                label="Installs"
                value={
                  Number(newGAPData?.installs) >= 0
                    ? Number(newGAPData?.installs)
                    : ''
                }
                onChange={(e) => {
                  setNewGAPData((data) => ({
                    ...data,
                    installs: parseFloat(e.target.value),
                  }));
                }}
                error={isNaN(newGAPData.installs) && isSubmittedOnce}
              />
              <TextField
                required
                type="number"
                id="cost"
                label="Cost"
                value={
                  Number(newGAPData?.cost) >= 0 ? Number(newGAPData?.cost) : ''
                }
                onChange={(e) => {
                  setNewGAPData((data) => ({
                    ...data,
                    cost: parseFloat(e.target.value),
                  }));
                }}
                error={isNaN(newGAPData.cost) && isSubmittedOnce}
              />
            </>
          ) : (
            ''
          )}
          {mode === 'DELETE_GAP' && (
            <div>Remove Google Ads Performance Record #{newGAPData.id}</div>
          )}
          {alertMessage !== '' && (
            <Alert severity="error">{alertMessage}</Alert>
          )}
        </>
      }
      bottom={
        <>
          <Button
            variant="contained"
            sx={{ marginRight: '10px' }}
            type="submit"
            onClick={(e) => handleSubmit(e)}
            disabled={isLoading}
          >
            {buttonText}
            {isLoading && (
              <CircularProgress
                style={{
                  color: 'white',
                  width: '15px',
                  height: '15px',
                  marginLeft: '5px',
                }}
              />
            )}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
        </>
      }
    ></CustomModal>
  );
};

export default GoogleAdsPerformanceModal;
