import { Axios } from './authApis';
import { MappingData } from '../components/InterfaceType';
import { Site } from '../store/channelMappingSlice';

export interface CM360SiteQuery {
  keyword?: string;
  page?: number;
}

interface Channel {
  name: string;
  sites: Site[];
}

interface MappingQuery {
  limit?: number;
  page: number;
  query: string;
}

export const createMapping = async (data: MappingData) => {
  const res2 = await Axios.get(`v1/channels?q=${data.channel.trim()}`);

  // check if channel already exists
  if (
    res2.data.results.length > 0 &&
    res2.data.results.some(
      (r: Channel) =>
        r.name.toLowerCase() === data.channel.trim().toLowerCase(),
    )
  ) {
    const targetChannel = res2.data.results.filter(
      (r: Channel) =>
        r.name.toLowerCase() === data.channel.trim().toLowerCase(),
    );

    const res = await Axios.patch(`v1/channels/${targetChannel[0].id}`, {
      name: targetChannel[0].channel,
      sites: [...targetChannel[0].sites, ...data.sites],
    });

    return res.data;
  } else {
    console.log('called');

    const res = await Axios.post(`v1/channels`, {
      id: data.id,
      name: data.channel.trim(),
      sites: data.sites,
    });
    console.log(res.data);
    return res.data;
  }
};

export async function getChannelsByPage({
  query,
  page,
  limit = 10,
}: MappingQuery) {
  const res = await Axios.get(
    `v1/channels?q=${query}&page=${page}&limit=${limit}`,
  );

  return res.data;
}

export async function getChannelsById(channelName: string) {
  const url = `/v1/channels/?${channelName}`;

  const res = await Axios.get(url);

  return res;
}

export const updateMapping = async (data: MappingData) => {
  const url = `/v1/channels/${data.id}`;

  // console.log('called');

  // console.log(data)

  // check if channel name already exists
  const res2 = await Axios.get(`v1/channels?q=${data.channel.trim()}`);
  if (
    res2.data.results.length > 0 &&
    res2.data.results.some(
      (r: Channel) =>
        r.name.toLowerCase() === data.channel.trim().toLowerCase(),
    )
  ) {
    const targetChannel = res2.data.results.filter(
      (r: Channel) =>
        r.name.toLowerCase() === data.channel.trim().toLowerCase(),
    );

    const res = await Axios.patch(`v1/channels/${targetChannel[0].id}`, {
      name: targetChannel[0].channel,
      sites: [...targetChannel[0].sites, ...data.sites],
    });

    return res;
  } else {
    const res = await Axios.patch(url, {
      id: data.id,
      name: data.channel.trim(),
      sites: data.sites,
    });

    return res;
  }
};

export const deleteMappingById = async (data: MappingData) => {
  const url = `v1/channels/${data.id}/sites/${data.sites[0].id}`;

  const res = await Axios.delete(url);

  return res;
};

export const deleteMappingByChannel = async (data: MappingData) => {
  const url = `v1/channels/${data.id}`;

  const res = await Axios.delete(url);

  return res;
};

export const getCm360Sites = async ({
  keyword = ' ',
  page = 1,
}: CM360SiteQuery) => {
  try {
    const res = await Axios.get(
      `v1/cm360-sites?q=${keyword}&page=${page}&limit=${10}`,
    );
    return res.data;
  } catch (err: unknown) {
    console.log(err);
  }
};
