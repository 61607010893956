import { MediaCostData, UploadExcelType } from '../components/InterfaceType';
import { tableHeader } from '../store/utilities';
import { Axios } from './authApis';

export interface CM360CampaignQuery {
  keyword?: string;
  page?: number;
}

export interface SourceQuery {
  keyword?: string;
  page?: number;
}
export interface Campaign {
  name: string;
  id: number;
}
export interface Source {
  name: string;
}

export interface FilterSortingLimitPage {
  startDate: string;
  endDate: string;
  filterTarget: string;
  filterValue: string;
  sortingTarget: string;
  sortingMethod: string;
  limit: number;
  page: number;
}

const PATH = 'v1/cm360-cost-records';

export const createMedia = async (data: MediaCostData) => {
  try {
    const url = `${PATH}`;

    let request = {
      startDate: data.startDate?.toISOString(),
      endDate: data.endDate?.toISOString(),
      cost: Number(data.cost),
      campaign: data.campaign,
      site: data.site,
      source: data.source,
      costAcPercentage:
        (data.costAcPercentage && Number(data.costAcPercentage) / 100),
    };

    return Axios.post(url, request).then((response) => {
      return response.data;
    });
  } catch (err) {
    throw err;
  }
};
export async function getMediaRecord(data: FilterSortingLimitPage) {
  try {
    const filter =
      data.filterTarget && data.filterValue
        ? '&filter[field]=' +
          tableHeader.filter(
            (item) => item.header === (data.filterTarget as string),
          )[0].type +
          '&filter[keyword]=' +
          data.filterValue
        : '';
    const sort =
      data.sortingMethod && data.sortingTarget && data.sortingMethod !== 'reset'
        ? '&sort[direction]=' +
          data.sortingMethod +
          '&sort[field]=' +
          data.sortingTarget
        : '';
    const url = `${PATH}?limit=${data.limit}&page=${data.page}${filter}${sort}`;

    return Axios.get(url).then((response) => {
      // handle success

      return response.data;
    });
  } catch (error) {
    console.log(error);
  }
}

export const updateMedia = async (data: MediaCostData) => {
  try {
    const url = `${PATH}/${data.id}`;
    
    let request = {
      startDate: data.startDate?.toISOString(),
      endDate: data.endDate?.toISOString(),
      cost: Number(data.cost),
      campaign: data.campaign,
      site: data.site,
      source: data.source,
      costAcPercentage:
        (data.costAcPercentage && Number(data.costAcPercentage) / 100),

    };
    console.log(request)
    return Axios.patch(url, request).then((response) => {
      return response.data;
    });
  } catch (err) {
    throw err;
  }
};

export const deleteMedia = async (data: MediaCostData) => {
  try {
    return Axios.delete(`${PATH}/${data.id}`).then((response) => {
      return response.status;
    });
  } catch (err) {
    console.log(err);
  }
};

export const excelUpload = async (
  formData: FormData,
  uploadExcelType: UploadExcelType,
): Promise<any> => {
  try {
    let url;

    if (uploadExcelType === UploadExcelType.Media)
      url = 'v1/cm360-cost-records/upload-excel-file';
    else if (uploadExcelType === UploadExcelType.GoggleAdsPerformance)
      url = 'v1/ads-campaign-setups/upload-excel-file';
    else if (uploadExcelType === UploadExcelType.GoogleAdsSetup)
      url = 'v1/ads-campaign-setups/upload-excel-file';

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    if (url) {
      const res = await Axios.post(url, formData, config);
      return res.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getExcelTemplate = async () => {
  try {
    const res = await Axios.get(`${PATH}/download-excel-template`, {
      responseType: 'blob',
    });
    const [, filename] = res.headers['content-disposition'].split('filename=');
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', filename.replace(/['"]/g, ''));
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    console.log(err);
  }
};

export const getCm360Campaign = async ({
  keyword = '',
  page = 1,
}: CM360CampaignQuery) => {
  try {
    const res = await Axios.get(`v1/cm360-campaigns?q=${keyword}&page=${page}`);

    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getSource = async ({ keyword = '', page = 1 }: SourceQuery) => {
  try {
    const res = await Axios.get(`v1/sources?q=${keyword}&page=${page}`);

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAutoComplete = async (
  { keyword = '', page = 1 }: SourceQuery,
  type: string,
) => {
  try {
    let target = '';

    if (type === 'Campaign Name') target = 'cm360-campaigns';
    else if (type === 'Site') target = 'cm360-sites';
    else if (type === 'Source') target = 'sources';

    const url = `v1/${target}?q=${keyword}&page=${page}`;
    const res = await Axios.get(url);

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAutoCompleteForModal = async (
  { keyword = '', page = 1 }: SourceQuery,
  type: string,
) => {
  try {
    const res = await Axios.get(`v1/${type}?q=${keyword}&page=${page}`);

    return res.data;
  } catch (err) {
    console.log(err);
  }
};
