import { Campaign, Source } from '../apis/mediaCostApis';
import { Site } from '../store/channelMappingSlice';
import { excelFileType } from '../store/mediaSlice';

export type MediaCostData = {
  id?: number | null;
  startDate: Date | null;
  endDate: Date | null;
  campaign: Campaign | null;
  site: Site | null;
  source: Source | null;
  cost: number;
  costWithAc: number;
  costAcPercentage: number;
  siteId?: number | null;
  excelFile?: string | null;
};

export type GoogleAdsSetupData = {
  id?: number | null;
  campaignId: string | null;
  campaignName: string | null;
  campaignType: string | null;
  campaignStatus: string | null;
  product: string | null;
  startDate: Date | null;
  endDate: Date | null;
  budget: number;
  excelFile?: excelFileType;
};

export type MappingData = {
  id?: number | null;
  channel: string;
  sites: {
    name: string;
    id: number;
  }[];
};

export type GoogleAdsPerformanceModalData = {
  id?: number | null;
  campaignId: string| null;
  campaignType: string | null;
  product: string | null;
  date: Date | null;
  conversionType: string | null;
  conversions: number;
  impressions: number;
  clicks: number;
  videoViews: number;
  cost: number;
  videoPlayedTo100Percentage: number;
  installs: number;
  adGroupName: string | null;
  adGroupId: string | null;
  viewThroughConversions: number;
};

export type ModalConfig = {
  mode: ModalMode;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export interface MappingModalConfig extends ModalConfig {
  mappingData: MappingData;
}

export interface MediaModalConfig extends ModalConfig {
  mediaCostData: MediaCostData;
}

export interface GoogleAdsPerformanceModalConfig extends ModalConfig {
  googleAdsPerformanceData: GoogleAdsPerformanceModalData;
}

export interface GoogleAdsSetupModalConfig extends ModalConfig {
  googleAdsSetupData: GoogleAdsSetupData;
}
export interface ExcelModalConfig extends ModalConfig {
  uploadExcelType: UploadExcelType;
}

export enum UploadExcelType {
  Media = 'Media',
  GoggleAdsPerformance = 'GoggleAdsPerformance',
  GoogleAdsSetup = 'GoogleAdsSetup',
  NotApply = 'NotApply',
}
export type AdsSetupModalConfig = {
  mode: ModalMode;
  adsSetupData: GoogleAdsSetupData;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export enum ModalMode {
  CreateMapping = 'CREATE_MAPPING',
  EditMapping = 'EDIT_MAPPING',
  EditChannelName = 'EDIT_CHANNEL_NAME',
  DeleteMappingById = 'DELETE_MAPPING_BY_ID',
  DeleteMappingByChannel = 'DELETE_MAPPING_BY_CHANNEL',
  CreateMedia = 'CREATE_MEDIA',
  EditMedia = 'EDIT_MEDIA',
  DeleteMedia = 'DELETE_MEDIA',
  UploadExcel = 'UPLOAD_EXCEL',
  CreateGAP = 'CREATE_GAP',
  EditGAP = 'EDIT_GAP',
  DeleteGAP = 'DELETE_GAP',
  CreateGAS = 'CREATE_GAS',
  EditGAS = 'EDIT_GAS',
  DeleteGAS = 'DELETE_GAS',
}

export enum Sort {
  ASC = 'ASC',
  DESC = 'DESC',
  DEFAULT = '',
}
