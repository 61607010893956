import { createSlice } from '@reduxjs/toolkit';
import { Axios } from '../apis/authApis';
import { RootState } from '.';

export interface UserState {
  accessToken?: string;
  username?: string;
  sub?: number;
  isLoggedIn?: boolean;
}

const initialState: UserState = {
  accessToken: sessionStorage.getItem('access_token') || undefined,
  isLoggedIn: !!sessionStorage.getItem('access_token') || false,
  username: sessionStorage.getItem('username') || undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.username = action.payload.username;
      state.isLoggedIn = true;

      sessionStorage.setItem('access_token', action.payload.accessToken || '');
      sessionStorage.setItem('username', action.payload.username || '');

      Axios.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${sessionStorage.getItem('access_token')}`;
    },
    logout: (state) => {
      sessionStorage.removeItem('access_token');
      sessionStorage.removeItem('username');

      state.accessToken = undefined;
      state.isLoggedIn = false;
      state.username = undefined;

      delete Axios.defaults.headers.common['Authorization'];
    },
  },
});

export const { login, logout } = userSlice.actions;

export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
