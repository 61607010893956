import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Container from '@mui/material/Container';
import Add from '@mui/icons-material/Add';
import Button from '@mui/material/Button';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import {
  ModalMode,
  Sort,
  UploadExcelType,
  GoogleAdsPerformanceModalConfig,
} from '../components/InterfaceType';
import ExcelModal from '../components/ExcelModal';
import { RootState } from '../store';
import {
  processPerformanceData,
  updateReduxFilterSorting,
} from '../store/utilities';
import SortIconStatus from '../components/SortIconStatus';
import {
  GoogleAdsPerformanceData,
  readGAPRecords,
  updateGAPPage,
  updateGAPRowsPerPageOptions,
  updateGAPSortingIcon,
  updateGAPTotalRecordNum,
} from '../store/googleAdsPerformanceSlice';
import GoogleAdsPerformanceModal from '../components/GoogleAdsPerformanceModal';
import FilterBoxForGoogleAds from '../components/FilterBoxForGoogleAds';
import { getGAPRecord } from '../apis/adsPerformanceApis';
import PageTitle from '../components/PageTitle';
import Section from '../components/Section';
import * as d3 from 'd3-format';
import DownloadIcon from '@mui/icons-material/Download';
import { getExcelTemplate } from '../apis/adsPerformanceApis';

const GoogleAdsPerformance = () => {
  const GAP = useSelector((state: RootState) => state.googleAdsPerformance);
  const {
    records: googleAdsPerformanceData,
    filterSortingOption,
    totalRecords: googleAdsPerformanceTotalRecords,
    filter: isFilterSet,
    filterTimes,
    sortIconStatus,
    rowsPerPage: googleAdsPerformanceRowsPerPage,
    page: googleAdsPerformanceTablePage,
    filterOptionsString: filterSortingString,
  } = GAP;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isExcelModalOpen, setIsExcelModalOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<GoogleAdsPerformanceModalConfig>({
    mode: ModalMode.CreateMapping,
    isOpen: false,
    setIsOpen: setIsOpen,
    googleAdsPerformanceData: {
      campaignId: null,
      campaignType: null,
      product: null,
      date: null,
      conversionType: null,
      adGroupName: null,
      adGroupId: null,
      conversions: 0,
      impressions: 0,
      clicks: 0,
      videoViews: 0,
      cost: 0,
      videoPlayedTo100Percentage: 0,
      installs: 0,
      viewThroughConversions: 0,
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const getRecords = async () => {
      let records = await getGAPRecord({
        filterTarget: filterSortingOption.filterTarget,
        filterValue: filterSortingOption.filterValue,
        sortingTarget: filterSortingOption.sortingTarget,
        sortingMethod: filterSortingOption.sortingMethod,
        limit: googleAdsPerformanceRowsPerPage,
        page: googleAdsPerformanceTablePage + 1,
      });

      let processed = processPerformanceData(records.results);
      // dispatch(updateMediaRecords(processed));
      dispatch(readGAPRecords(processed));
      dispatch(updateGAPTotalRecordNum(records.total));
    };
    getRecords();
  }, [
    googleAdsPerformanceTablePage,
    googleAdsPerformanceRowsPerPage,
    isFilterSet,
    sortIconStatus,
    filterTimes,
    googleAdsPerformanceTotalRecords,
  ]);

  const handleCreateGoogleAdsPerformance = () => {
    setOptions((options) => ({
      ...options,
      mode: ModalMode.CreateGAP,
      googleAdsPerformanceData: {
        campaignId: null,
        campaignType: null,
        product: null,
        date: null,
        conversionType: null,
        adGroupName: null,
        adGroupId: null,
        videoPlayedTo100Percentage: 0,
        installs: 0,
        conversions: 0,
        impressions: 0,
        clicks: 0,
        videoViews: 0,
        cost: 0,
        viewThroughConversions: 0,
      },
      isOpen: true,
      setIsOpen: setIsOpen,
    }));
    setIsOpen(true);
  };

  const handleEditDeleteGoogleAdsPerformance = (
    row: GoogleAdsPerformanceData,
    modalMode: ModalMode,
  ) => {
    setOptions((options) => ({
      ...options,
      mode: modalMode,
      googleAdsPerformanceData: {
        ...row,
        date: new Date(row.date),
      },
      isOpen: true,
      setIsOpen: setIsOpen,
    }));
    setIsOpen(true);
  };

  const handleCreateGoogleAdsPerformanceByExcel = () => {
    setOptions((options) => ({
      ...options,
      mode: ModalMode.UploadExcel,
      isOpen: true,
      setIsOpen: setIsExcelModalOpen,
    }));
    setIsExcelModalOpen(true);
  };

  const handleGAPChangePage = (event: unknown, newPage: number) => {
    dispatch(updateGAPPage({ page: newPage }));
  };

  const handleGAPChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(
      updateGAPRowsPerPageOptions({
        rowsPerPage: parseInt(event.target.value, 10),
      }),
    );
  };

  const handleGetTemplate = async () => {
    await getExcelTemplate();
  };

  const sortHandlerGAP = (type: string, mode: string) => {

    updateReduxFilterSorting(
      type,
      'sortingTarget',
      UploadExcelType.GoggleAdsPerformance,
    );
    updateReduxFilterSorting(
      mode,
      'sortingMethod',
      UploadExcelType.GoggleAdsPerformance,
    );

    if (!type) dispatch(updateGAPSortingIcon({ type: '', mode: Sort.DEFAULT }));

    mode === 'DESC'
      ? dispatch(updateGAPSortingIcon({ type: type, mode: Sort.DESC }))
      : dispatch(updateGAPSortingIcon({ type: type, mode: Sort.ASC }));
  };

  return (
    <div className="GoogleAdsPerformance">
      <PageTitle>Google Ads Performance</PageTitle>
      <Section>
        <Container maxWidth="xl">
          <div className="flex flex-wrap gap-4">
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={handleCreateGoogleAdsPerformance}
            >
              NEW
            </Button>
            <Button
              variant="contained"
              color="success"
              startIcon={<FileUploadIcon />}
              onClick={handleCreateGoogleAdsPerformanceByExcel}
            >
              UPLOAD EXCEL
            </Button>
            <Button
              variant="contained"
              color="info"
              component="label"
              onClick={handleGetTemplate}
              startIcon={<DownloadIcon />}
            >
              Excel Template
            </Button>

            <FilterBoxForGoogleAds
              tableType={UploadExcelType.GoggleAdsPerformance}
            />
          </div>
        </Container>
      </Section>
      <Section>
        <Container maxWidth="xl">
          <span className="text-gray-400">{filterSortingString}</span>
        </Container>
      </Section>
      <Section>
        <Container maxWidth="xl">
          {googleAdsPerformanceData.length > 0 ? (
            <>
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell className="min-w-[160px]">
                        <SortIconStatus
                          type="campaignId"
                          mode={sortIconStatus.campaignId}
                          clickHandler={sortHandlerGAP}
                          tableType={UploadExcelType.GoggleAdsPerformance}
                          showName="Campaign ID"
                        />
                      </TableCell>
                      <TableCell className="min-w-[180px]">
                        <SortIconStatus
                          type="campaignType"
                          mode={sortIconStatus.campaignType}
                          clickHandler={sortHandlerGAP}
                          tableType={UploadExcelType.GoggleAdsPerformance}
                          showName="Campaign Type"
                        />
                      </TableCell>
                      <TableCell className="min-w-[125px]">
                        <SortIconStatus
                          type="product"
                          mode={sortIconStatus.product}
                          clickHandler={sortHandlerGAP}
                          tableType={UploadExcelType.GoggleAdsPerformance}
                          showName="Product"
                        />
                      </TableCell>
                      <TableCell className="min-w-[180px]">
                        <SortIconStatus
                          type="adGroupName"
                          mode={sortIconStatus.adGroupName}
                          clickHandler={sortHandlerGAP}
                          tableType={UploadExcelType.GoggleAdsPerformance}
                          showName="Ad Group Name"
                        />
                      </TableCell>
                      <TableCell className="min-w-[180px]">
                        <SortIconStatus
                          type="adGroupId"
                          mode={sortIconStatus.adGroupId}
                          clickHandler={sortHandlerGAP}
                          tableType={UploadExcelType.GoggleAdsPerformance}
                          showName="Ad Group ID"
                        />
                      </TableCell>
                      <TableCell className="min-w-[105px]">
                        <SortIconStatus
                          type="date"
                          mode={sortIconStatus.date}
                          clickHandler={sortHandlerGAP}
                          tableType={UploadExcelType.GoggleAdsPerformance}
                          showName="Date"
                        />
                      </TableCell>
                      <TableCell className="min-w-[150px]">
                        Conversion Type
                      </TableCell>
                      <TableCell align="right" className="min-w-[155px]">
                        <SortIconStatus
                          type="conversions"
                          mode={sortIconStatus.conversions}
                          clickHandler={sortHandlerGAP}
                          tableType={UploadExcelType.GoggleAdsPerformance}
                          showName="Conversions"
                        />
                      </TableCell>
                      <TableCell align="right" className="min-w-[155px]">
                        <SortIconStatus
                          type="impressions"
                          mode={sortIconStatus.impressions}
                          clickHandler={sortHandlerGAP}
                          tableType={UploadExcelType.GoggleAdsPerformance}
                          showName="Impressions"
                        />
                      </TableCell>
                      <TableCell align="right" className="min-w-[115px]">
                        <SortIconStatus
                          type="clicks"
                          mode={sortIconStatus.clicks}
                          clickHandler={sortHandlerGAP}
                          tableType={UploadExcelType.GoggleAdsPerformance}
                          showName="Clicks"
                        />
                      </TableCell>
                      <TableCell align="right" className="min-w-[160px]">
                        <SortIconStatus
                          type="videoViews"
                          mode={sortIconStatus.videoViews}
                          clickHandler={sortHandlerGAP}
                          tableType={UploadExcelType.GoggleAdsPerformance}
                          showName="Video Views"
                        />
                      </TableCell>
                      <TableCell align="right" className="min-w-[210px]">
                        <SortIconStatus
                          type="videoPlayedTo100Percentage"
                          mode={sortIconStatus.videoPlayedTo100Percentage}
                          clickHandler={sortHandlerGAP}
                          tableType={UploadExcelType.GoggleAdsPerformance}
                          showName="Video played to 100%"
                        />
                      </TableCell>
                      <TableCell align="right" className="min-w-[140px]">
                        <SortIconStatus
                          type="installs"
                          mode={sortIconStatus.installs}
                          clickHandler={sortHandlerGAP}
                          tableType={UploadExcelType.GoggleAdsPerformance}
                          showName="Installs"
                        />
                      </TableCell>
                       <TableCell align="right" className="min-w-[240px]">
                        <SortIconStatus
                          type="viewThroughConversions"
                          mode={sortIconStatus.viewThroughConversions}
                          clickHandler={sortHandlerGAP}
                          tableType={UploadExcelType.GoggleAdsPerformance}
                          showName="View-through Conversions"
                        />
                      </TableCell>

                      <TableCell align="right" className="min-w-[105px]">
                        <SortIconStatus
                          type="cost"
                          mode={sortIconStatus.cost}
                          clickHandler={sortHandlerGAP}
                          tableType={UploadExcelType.GoggleAdsPerformance}
                          showName="Cost"
                        />
                      </TableCell>
                      <TableCell className="min-w-[200px]">
                        Data Source
                      </TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {googleAdsPerformanceData.map(
                      (row: GoogleAdsPerformanceData, index: number) => (
                        <TableRow
                          key={index}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell>{row.id}</TableCell>
                          <TableCell>{row.campaignId}</TableCell>
                          <TableCell>{row.campaignType}</TableCell>
                          <TableCell>{row.product}</TableCell>
                          <TableCell>{row.adGroupName}</TableCell>
                          <TableCell>{row.adGroupId}</TableCell>
                          <TableCell>{row.date}</TableCell>
                          <TableCell>{row.conversionType}</TableCell>
                          <TableCell align="right">
                            {d3.format(',.0f')(row.conversions)}
                          </TableCell>
                          <TableCell align="right">
                            {d3.format(',.0f')(row.impressions)}
                          </TableCell>
                          <TableCell align="right">
                            {d3.format(',.0f')(row.clicks)}
                          </TableCell>
                          <TableCell align="right">
                            {d3.format(',.0f')(row.videoViews)}
                          </TableCell>

                          <TableCell align="right">
                            {d3.format(',.0f')(row.videoPlayedTo100Percentage)}
                          </TableCell>
                          <TableCell align="right">
                            {d3.format(',.0f')(row.installs)}
                          </TableCell>
                          <TableCell align="right">
                            {d3.format(',.0f')(row.viewThroughConversions)}
                          </TableCell>

                          <TableCell align="right">
                            {d3.format(',.2f')(row.cost)}
                          </TableCell>
                          <TableCell>{row.excelFile.name}</TableCell>
                          <TableCell className="min-w-[115px]" align="right">
                            <IconButton
                              onClick={(e) =>
                                handleEditDeleteGoogleAdsPerformance(
                                  row,
                                  ModalMode.EditGAP,
                                )
                              }
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={(e) =>
                                handleEditDeleteGoogleAdsPerformance(
                                  row,
                                  ModalMode.DeleteGAP,
                                )
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={googleAdsPerformanceTotalRecords}
                rowsPerPage={googleAdsPerformanceRowsPerPage}
                page={googleAdsPerformanceTablePage}
                onPageChange={handleGAPChangePage}
                onRowsPerPageChange={handleGAPChangeRowsPerPage}
              />
            </>
          ) : (
            <Section>There is no record.</Section>
          )}
        </Container>
      </Section>
      {isOpen && <GoogleAdsPerformanceModal {...options} />}
      {isExcelModalOpen && (
        <ExcelModal
          mode={ModalMode.UploadExcel}
          isOpen={isExcelModalOpen}
          setIsOpen={setIsExcelModalOpen}
          uploadExcelType={UploadExcelType.GoggleAdsPerformance}
        />
      )}
    </div>
  );
};

export default GoogleAdsPerformance;
