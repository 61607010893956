import React, { useRef, useState } from 'react';
import { Button, Alert, Box } from '@mui/material';
import { excelUpload } from '../apis/mediaCostApis';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { ExcelModalConfig } from './InterfaceType';
import CircularProgress from '@mui/material/CircularProgress';
import CustomModal from './CustomModal';
import { useDispatch } from 'react-redux';
import { createAdsSetupByExcel } from '../store/adsSetupSlice';
import { createMediaRecordsByExcel } from '../store/mediaSlice';
import { createGAPRecordsByExcel } from '../store/googleAdsPerformanceSlice';

const ExcelModal: React.FC<ExcelModalConfig> = ({
  mode,
  setIsOpen,
  uploadExcelType,
}) => {
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File>(new File([''], '')); // initialize empty file
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fileRef = useRef<any>();
  const dispatch = useDispatch();

  const handleFileChange = (event: React.FormEvent): void => {
    const files = (event.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      setSelectedFile(files[0]);
      setSuccessMessage('');
      setAlertMessage('');
    }
  };

  const handleExcelSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', selectedFile as Blob, selectedFile?.name);
    try {
      if (
        selectedFile.size > 0 &&
        selectedFile.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        await excelUpload(formData, uploadExcelType);
        if (uploadExcelType === 'Media') dispatch(createMediaRecordsByExcel());
        else if (uploadExcelType === 'GoogleAdsSetup')
          dispatch(createAdsSetupByExcel());
        else if (uploadExcelType === 'GoggleAdsPerformance')
          dispatch(createGAPRecordsByExcel());

        setSuccessMessage('File is uploaded successfully');
        setIsOpen(false);
      } else if (
        selectedFile.type !==
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
        selectedFile.type
      ) {
        setAlertMessage('Wrong file type');
        setSuccessMessage('');
        return;
      } else {
        setAlertMessage('No file is chosen');
        setSuccessMessage('');
        return;
      }
    } catch (err: any) {
      setAlertMessage(err.response.data.message);
      setSuccessMessage('');
    } finally {
      setIsLoading(false);
      setSelectedFile(new File([''], ''));
      fileRef.current.value = '';
    }
  };

  return (
    <CustomModal
      top={'Upload Excel File'}
      middle={
        <>
          {alertMessage !== '' && (
            <Alert severity="error">{alertMessage}</Alert>
          )}
          {successMessage !== '' && (
            <Alert severity="success">{successMessage}</Alert>
          )}
          {mode === 'UPLOAD_EXCEL' && (
            <>
              <Box
                onSubmit={handleExcelSubmit}
                id="excel-form"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Button
                  variant="contained"
                  color="warning"
                  component="label"
                  className="flex-1"
                  style={{ width: '200px' }}
                >
                  <TextSnippetOutlinedIcon style={{ marginRight: '10px' }} />
                  Choose File
                  <input
                    ref={fileRef}
                    // value={selectedFile.name}
                    type="file"
                    onChange={handleFileChange}
                    hidden
                  />
                </Button>
                <div style={{ marginLeft: '10px' }} className="flex-1">
                  {selectedFile.name}
                </div>
              </Box>
            </>
          )}
        </>
      }
      bottom={
        <>
          <Button
            variant="contained"
            sx={{ marginRight: '10px' }}
            type="submit"
            onClick={(e) => handleExcelSubmit(e)}
            disabled={isLoading}
          >
            Submit
            {isLoading && (
              <CircularProgress
                style={{
                  color: 'white',
                  width: '15px',
                  height: '15px',
                  marginLeft: '5px',
                }}
              />
            )}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
        </>
      }
    />
  );
};

export default ExcelModal;
