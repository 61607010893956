import { Axios } from './authApis';
import { GoogleAdsPerformanceModalData } from '../components/InterfaceType';
import { GAPfilterOptions } from '../components/FilterBoxForGoogleAds';

export interface FilterSortingLimitPageGAP {
  filterTarget: string;
  filterValue: string;
  sortingTarget: string;
  sortingMethod: string;
  limit: number;
  page: number;
}

const PATH = 'v1/ads-campaign-performances';

export const createAdsPerformance = async (
  data: GoogleAdsPerformanceModalData,
) => {
  try {
    const url = `${PATH}`;
    let request = {
      ...data,
      campaignId: data.campaignId?.toString(),
      campaignType: data.campaignType?.toString(),
      product: data.product,
      date: data.date?.toISOString(),
    };

    return Axios.post(url, request).then((response) => {
      return response.data;
    });
  } catch (err) {
    throw err;
  }
};

export async function getGAPRecord(data: FilterSortingLimitPageGAP) {
  try {
    const filter =
      data.filterTarget && data.filterValue
        ? '&filter[field]=' +
          GAPfilterOptions.filter(
            (item) => item.header === (data.filterTarget as string),
          )[0].type +
          '&filter[keyword]=' +
          data.filterValue
        : '';
    const sort =
      data.sortingMethod && data.sortingTarget && data.sortingMethod !== 'reset'
        ? '&sort[direction]=' +
          data.sortingMethod +
          '&sort[field]=' +
          data.sortingTarget
        : '';
    const url = `${PATH}?limit=${data.limit}&page=${data.page}${filter}${sort}`;

    return Axios.get(url).then((response) => {
      // handle success
      return response.data;
    });
    // .catch(function (error) {
    //   // handle error

    // });
  } catch (error) {
    console.log(error);
  }
}

export const updateGAPById = async (data: GoogleAdsPerformanceModalData) => {
  try {
    const url = `${PATH}/${data.id}`;

    let request = {
      ...data,
      campaignId: data.campaignId?.toString(),
      campaignType: data.campaignType?.toString(),
      product: data.product,
      date: data.date?.toISOString(),
      cost: Number(data.cost),
    };

    return Axios.patch(url, request).then((response) => {
      return response.data;
    });
  } catch (err) {
    throw err;
  }
};

export const deleteGAPById = async (data: GoogleAdsPerformanceModalData) => {
  try {
    return Axios.delete(`${PATH}/${data.id}`).then((response) => {
      return response.status;
    });
  } catch (err) {
    throw err;
  }
};

export const getExcelTemplate = async () => {
  try {
    const res = await Axios.get(`${PATH}/download-excel-template`, {
      responseType: 'blob',
    });
    const [, filename] = res.headers['content-disposition'].split('filename=');
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', filename.replace(/['"]/g, ''));
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    console.log(err);
  }
};
