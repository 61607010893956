import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '.';

export interface ReturnedMappingPage {
  page_total: number;
  results: Mapping[];
  total: number;
}
export interface Mapping {
  id: number;
  name: string;
  sites: Site[];
}

export interface Site {
  id: number;
  name: string;
}

export interface Campaign {
  id: number;
  name: string;
  label?: string;
}

export interface ChannelMappingState {
  mapping: Mapping[];
  page_total: number;
  total: number;
  currentPage: number;
  limit: number;
}

const initialState: ChannelMappingState = {
  mapping: [],
  page_total: 0,
  total: 0,
  currentPage: 0,
  limit: 10,
};

export const channelMappingSlice = createSlice({
  name: 'mapping',
  initialState,
  reducers: {
    createNewMapping: (state, action) => {
      // action contains:
      // channel name:string
      // sites: Site[]
      // search whether channel array existed in state
      const channelIndex = state.mapping.findIndex(
        (item) => item.name === action.payload.channelName,
      );

      if (state.mapping.length < state.limit)
        if (channelIndex >= 0) {
          // Yes -> insert to sites[]
          let temp = [...state.mapping];
          let process = action.payload.sites.map((i: Site) => ({
            id: i.id,
            name: i.name,
          }));
          temp[channelIndex].sites = [...temp[channelIndex].sites, ...process];
          // temp[channelIndex].sites.push(action.payload.sites);
          // remove duplicate items

          temp[channelIndex].sites = _.uniqWith(
            temp[channelIndex].sites,
            _.isEqual,
          );

          // remove other channel's site
          const temp2 = temp.map((item) =>
            // item.id === action.payload.channelId && item.sites.
            {
              return {
                ...item,
                sites: item.sites.filter((i) => {
                  return !(
                    action.payload.sites.some(
                      (matchSite: Site) => matchSite.id === i.id,
                    ) && item.id !== action.payload.channelId
                  );
                }),
              };
            },
          );
          state.mapping = [...temp2];

          // state.total = action.payload.total;
          state.total--;
        } else {
          // No -> create new item in channel array
          const temp = [...state.mapping];
          const tempChannel = {
            id: action.payload.channelId,
            name: action.payload.channelName,

            sites: action.payload.sites,
          };
          temp.push(tempChannel);
          const temp2 = temp.map((item) =>
            // item.id === action.payload.channelId && item.sites.
            {
              return {
                ...item,
                sites: item.sites.filter((i) => {
                  return !(
                    action.payload.sites.some(
                      (matchSite: Site) => matchSite.id === i.id,
                    ) && item.id !== action.payload.channelId
                  );
                }),
              };
            },
          );
          state.mapping = [];
          state.mapping = [...temp2];
        }

      state.total++;
    },
    deleteMappingSliceById: (state, action) => {
      // Added
      const temp = state.mapping.map((item) =>
        // item.id === action.payload.channelId && item.sites.
        {
          return {
            ...item,
            sites: item.sites.filter(
              (i) =>
                !(
                  i.id === action.payload.siteId &&
                  item.id === action.payload.channelId
                ),
            ),
          };
        },
      );
      state.mapping = [];
      state.mapping = [...temp];

      state.total--;
    },
    updateMapping: (state, action) => {
      // just simple fetch data from database to update the mapping array
      // Added

      const { results, total } = action.payload;

      state.mapping = [];
      state.mapping = [...results];
      // state.totalPage = Math.ceil(total / state.limit) || 1;
      // state.page_total = page_total;
      state.total = total;
    },
    updatePage: (state, action) => {
      // just simple fetch data from database to update the mapping array
      // Added

      state.currentPage = action.payload.page;
    },
    updateRowsPerPage: (state, action) => {
      state.limit = action.payload.limit;
      state.currentPage = 0;
    },
    deleteMappingSliceByChannel: (state, action) => {
      // Added
      const temp = state.mapping.filter(
        (item) => item.id !== action.payload.id,
      );
      state.mapping = [];
      state.mapping = [...temp];

      if (state.mapping.length === 0 && state.currentPage > 0) {
        state.currentPage--;
      }

      state.total--;
    },
    updateMappingSliceChannelName: (state, action) => {
      // Added
      // pass id and new channelName

      const channelIndex = state.mapping.findIndex(
        (item) => item.id === action.payload.channelId,
      );

      const temp = [...state.mapping];

      if (channelIndex >= 0) {
        const temp2 = temp.map((item) => ({
          ...item,
          sites: item.sites.filter(
            // remove other channel that have the same site
            (sites) =>
              !action.payload.sites.some(
                (site: Site) => site.name === sites.name,
              ),
          ),
        }));
        temp2[channelIndex].name = action.payload.newChannelName;
        temp2[channelIndex].sites = [...action.payload.sites];
        state.mapping = [];
        state.mapping = [...temp2];
      }
    },
  },
});

export const {
  createNewMapping,
  updateMapping,
  updatePage,
  deleteMappingSliceById,
  deleteMappingSliceByChannel,
  updateMappingSliceChannelName,
  updateRowsPerPage,
} = channelMappingSlice.actions;
export const getMapping = (state: RootState) => state.mapping.mapping;
export default channelMappingSlice.reducer;
