import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import mediaReducer from './mediaSlice';
import channelMappingReducer from './channelMappingSlice';
import googleAdsPerformanceReducer from './googleAdsPerformanceSlice';
import adsSetupReducer from './adsSetupSlice';
export const store = configureStore({
  reducer: {
    user: userReducer,
    media: mediaReducer,
    mapping: channelMappingReducer,
    googleAdsPerformance: googleAdsPerformanceReducer,
    setup: adsSetupReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
