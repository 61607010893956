import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { Autocomplete, TextField, Button, Alert } from '@mui/material';
import {
  createMapping,
  updateMapping,
  deleteMappingById,
  deleteMappingByChannel,
} from '../apis/mappingApis';
import { getCm360Sites } from '../apis/mappingApis';
import CircularProgress from '@mui/material/CircularProgress';
import {
  MappingData,
  MappingModalConfig,
  ModalMode,
} from './InterfaceType';
import { useDispatch } from 'react-redux';
import {
  createNewMapping,
  deleteMappingSliceByChannel,
  deleteMappingSliceById,
  updateMappingSliceChannelName,
} from '../store/channelMappingSlice';

import CustomModal from './CustomModal';
interface Option {
  name: string;
  id: number;
  label: string;
}

const MappingModal: React.FC<MappingModalConfig> = ({
  isOpen,
  mode,
  setIsOpen,
  mappingData,
}) => {
  const [newMappingData, setNewMappingData] =
    useState<MappingData>(mappingData);
  const [isSubmittedOnce, setIsSubmittedOnce] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState<boolean>(false);
  const [siteOptions, setSiteOptions] = useState<Option[]>([]);
  const [query, setQuery] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  // const multipleSite = useSelector(
  //   (state: RootState) => state.mapping.mapping
  // );
  const dispatch = useDispatch();

  const observer = useRef<null | IntersectionObserver>();

  const lastOptionRef = useCallback((node: any) => {
    if (isLoadingOptions) return;
    if (observer.current) {
      observer.current.disconnect();
    }
    observer.current = new IntersectionObserver(async (entries) => {
      if (entries[0].isIntersecting) {
        setCurrentPage((currentPage) => currentPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  useEffect(() => {
    const getSites = async () => {
      try {
        setIsLoadingOptions(true);
        const { results } = await getCm360Sites({
          keyword: query,
          page: currentPage,
        });
        if (results.length) {
          if (currentPage === 1) {
            setSiteOptions(
              results.map((d: Option) => ({
                label: d.name,
                id: d.id,
                name: d.name,
              })),
            );
          } else {
            setSiteOptions((options) => [
              ...options,
              ...results.map((d: Option) => ({
                label: d.name,
                id: d.id,
                name: d.name,
              })),
            ]);
            setIsLoadingOptions(false);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    getSites();
  }, [query, currentPage]);

  const isFieldNonEmpty = (obj: MappingData) => {
    if (mode === 'CREATE_MAPPING' && obj.sites.length === 0) return false;
    return Object.values(obj).every((field) => field !== null);
  };

  const buttonText = useMemo(() => {
    if (mode === 'CREATE_MAPPING') return 'Create';
    if (mode === 'EDIT_MAPPING' || mode === 'EDIT_CHANNEL_NAME') return 'Save';
    if (mode === 'DELETE_MAPPING_BY_ID' || mode === 'DELETE_MAPPING_BY_CHANNEL')
      return 'Confirm';
  }, [mode]);

  const titleText = useMemo(() => {
    if (mode === 'CREATE_MAPPING') return 'New Mapping';
    else if (mode === 'EDIT_CHANNEL_NAME') return 'Edit Channel Name';
    else if (
      mode === 'DELETE_MAPPING_BY_ID' ||
      mode === 'DELETE_MAPPING_BY_CHANNEL'
    )
      return 'Delete Mapping';

    return '';
  }, [mode]);

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): Promise<void> => {
    e.preventDefault();
    setIsSubmittedOnce(true);
    setAlertMessage('');

    if (
      !isFieldNonEmpty(newMappingData) &&
      mode !== 'EDIT_CHANNEL_NAME' &&
      mode !== 'DELETE_MAPPING_BY_CHANNEL'
    ) {
      setAlertMessage('Please fill all required fields');
      return;
    } else if (newMappingData.channel === '') {
      setAlertMessage('Please fill all required fields');
      return;
    }

    try {
      let res;
      setIsLoading(true);
      switch (mode) {
        case ModalMode.CreateMapping:
          let createdMapping = await createMapping(newMappingData);
          dispatch(
            createNewMapping({
              channelId: createdMapping.id,
              channelName: createdMapping.name,
              sites: createdMapping.sites,
            }),
          );
          break;
        case ModalMode.DeleteMappingById:
          res = await deleteMappingById(newMappingData);
          if (res.status === 200) {
            dispatch(
              deleteMappingSliceById({
                channelId: newMappingData.id,
                siteId: newMappingData.sites[0].id,
              }),
            );
          }
          break;
        case ModalMode.EditMapping:
          res = await updateMapping(newMappingData);

          if (res.status === 200) {
            dispatch(
              updateMappingSliceChannelName({
                channelId: res.data.id,
                newChannelName: res.data.name,
                sites: res.data.sites,
              }),
            );
          }
          break;
        case ModalMode.DeleteMappingByChannel:
          res = await deleteMappingByChannel(newMappingData);
          if (res.status === 200) {
            dispatch(deleteMappingSliceByChannel({ id: newMappingData.id }));
          }
          break;
        default:
          break;
      }

      setIsLoading(false);
      setIsLoadingOptions(false);
      setIsOpen(false);
    } catch (err: any) {
      setIsLoading(false);
      if (err.response.status === 500) {
        setAlertMessage('This channel name already exists');
      } else {
        handleSetAlertMessage(err);
      }
    }
  };

  const handleSetAlertMessage = (err: any) => {
    if (Array.isArray(err.response.data.message))
      setAlertMessage(err.response.data.message[0]);
    else {
      setAlertMessage(err.response.data.message);
    }
  };

  return (
    <CustomModal
      top={titleText}
      middle={
        <>
          {mode === 'EDIT_MAPPING' ||
          mode === 'CREATE_MAPPING' ||
          mode === 'EDIT_CHANNEL_NAME' ? (
            <>
              <TextField
                id="channel-name"
                value={newMappingData.channel}
                onChange={(e) =>
                  setNewMappingData((data) => ({
                    ...data,
                    channel: e.target.value,
                  }))
                }
                error={newMappingData.channel === '' && isSubmittedOnce}
                label={'Channel Name'}
                required
              />
              {/* {mode !== 'EDIT_CHANNEL_NAME' && (
                
              )} */}
              <Autocomplete
                multiple
                id="cm-site"
                options={siteOptions}
                getOptionLabel={(option) => option.label}
                value={newMappingData.sites.map((site) => ({
                  label: site.name,
                  name: site.name,
                  id: site.id,
                }))}
                renderOption={(props, option) => {
                  if (siteOptions[siteOptions.length - 1].id === option.id) {
                    return (
                      <li
                        {...props}
                        data-name={option.label}
                        ref={lastOptionRef}
                      >
                        {option.label}
                      </li>
                    );
                  }

                  return (
                    <li {...props} data-name={option.label}>
                      {option.label}
                    </li>
                  );
                }}
                isOptionEqualToValue={(option, value) => {
                  return option.name === value.name;
                }}
                onChange={(e, option) => {
                  setNewMappingData((data) => ({
                    ...data,

                    sites: option.map((o) => ({
                      name: o.name,
                      id: o.id,
                      label: o.name,
                    })),
                  }));

                  setQuery('');
                  setCurrentPage(1);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="CM site"
                    error={newMappingData.sites.length === 0 && isSubmittedOnce}
                  />
                )}
              />
            </>
          ) : (
            ''
          )}
          {mode === 'DELETE_MAPPING_BY_ID' && (
            <div>Remove Mapping with Site ID: {newMappingData.sites[0].id}</div>
          )}
          {mode === 'DELETE_MAPPING_BY_CHANNEL' && (
            <div>
              Remove All Mapping Record related to {newMappingData.channel}
            </div>
          )}
          {alertMessage !== '' && (
            <Alert severity="error">{alertMessage}</Alert>
          )}
        </>
      }
      bottom={
        <>
          {' '}
          <Button
            variant="contained"
            sx={{ marginRight: '10px' }}
            type="submit"
            onClick={(e) => handleSubmit(e)}
            disabled={isLoading}
          >
            {buttonText}
            {isLoading && (
              <CircularProgress
                style={{
                  color: 'white',
                  width: '15px',
                  height: '15px',
                  marginLeft: '5px',
                }}
              />
            )}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
        </>
      }
    />
  );
};

export default MappingModal;
