import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

const Layout = () => {
  return (
    <main className="wrapper min-h-screen flex flex-col">
      <Header />
      <div className="page flex-1">
        <Outlet />
      </div>
      <Footer />
    </main>
  );
};

export default Layout;
