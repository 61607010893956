import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Sort } from '../components/InterfaceType';
import { excelFileType } from './mediaSlice';

export interface GoogleAdsPerformanceData {
  id: number;
  campaignId: string;
  campaignType: string;
  product: string;
  date: string;
  conversionType: string;
  conversions: number;
  impressions: number;
  clicks: number;
  videoViews: number;
  cost: number;
  adGroupName: string;
  adGroupId: string;
  videoPlayedTo100Percentage: number;
  viewThroughConversions: number;
  installs: number;
  excelFile: excelFileType;
}

export interface GoogleAdsPerformanceState {
  records: GoogleAdsPerformanceData[];
  totalRecords: number;
  page: number;
  rowsPerPage: number;
  filter: boolean;
  filterTimes: 0;
  filterSortingOption: {
    filterTarget: string;
    filterValue: string;
    sortingTarget: string;
    sortingMethod: string;
  };
  filterOptionsString: string;
  sortIconStatus: {
    campaignId: Sort;
    campaignType: Sort;
    product: Sort;
    date: Sort;
    conversions: Sort;
    impressions: Sort;
    clicks: Sort;
    videoViews: Sort;
    cost: Sort;
    videoPlayedTo100Percentage: Sort;
    viewThroughConversions:Sort;
    installs: Sort;
    adGroupName: Sort;
    adGroupId: Sort;
  };
}

const initialState: GoogleAdsPerformanceState = {
  records: [],
  totalRecords: 0,
  page: 0,
  rowsPerPage: 25,
  filter: false,
  filterTimes: 0,
  filterSortingOption: {
    filterTarget: '',
    filterValue: '',
    sortingTarget: '',
    sortingMethod: '',
  },
  filterOptionsString: '',
  sortIconStatus: {
    campaignId: Sort.DEFAULT,
    campaignType: Sort.DEFAULT,
    product: Sort.DEFAULT,
    date: Sort.DEFAULT,
    conversions: Sort.DEFAULT,
    impressions: Sort.DEFAULT,
    clicks: Sort.DEFAULT,
    videoViews: Sort.DEFAULT,
    cost: Sort.DEFAULT,
    adGroupName: Sort.DEFAULT,
    adGroupId: Sort.DEFAULT,
    viewThroughConversions: Sort.DEFAULT,
    videoPlayedTo100Percentage: Sort.DEFAULT,
    installs: Sort.DEFAULT,
  },
};

export const googleAdsPerformanceSlice = createSlice({
  name: 'googleAdsPerformance',
  initialState,
  reducers: {
    updateGAPRowsPerPageOptions: (state, action) => {
      state.rowsPerPage = action.payload.rowsPerPage;
      state.page = 0;
    },

    updateGAPPage: (state, action) => {
      state.page = action.payload.page;
    },

    updateGAPTotalRecordNum: (state, action) => {
      state.totalRecords = action.payload;
    },

    readGAPRecords: (state, action) => {
      // // just simple fetch data from database to update the Google Ads Performance array
      state.records = [];
      state.records = [...action.payload];
    },

    createGAPRecordsByExcel: (state) => {
      state.totalRecords = state.totalRecords + 1;
    },

    createGAPRecords: (state, action) => {
      if (state.records.length < state.rowsPerPage) {
        const newRecord = {
          ...action.payload,
          excelFile: {
            dataType: '',
            id: NaN,
            name: 'Manual Input',
            storagePath: '',
          },
        };
        state.records.push(newRecord);
      }
      state.totalRecords = state.totalRecords + 1;
    },

    updateGAPRecordByCampaignId: (state, action) => {
      const recordIndex = state.records.findIndex(
        (item) => item.id === action.payload.id,
      );

      const temp = [...state.records];
      if (recordIndex >= 0) {
        // merge the updated record to original
        temp[recordIndex] = _.merge(temp[recordIndex], action.payload);
        state.records = [];
        state.records = [...temp];
      }
    },

    deleteGAPRecordsById: (state, action) => {
      // Added
      const temp = state.records.filter(
        (item) =>
          // item.id === action.payload.channelId && item.sites.
          item.id !== action.payload.id,
      );
      state.records = [];
      state.records = [...temp];
      if (state.records.length === 0 && state.page > 0) {
        state.page--;
      }
      state.totalRecords--;
    },

    updateGAPFilterState: (state, action) => {
      state.filter = action.payload.state;
    },

    updateGAPFilterSortingOptions: (state, action) => {
      state.filterSortingOption = _.merge(
        state.filterSortingOption,
        action.payload,
      );
    },

    updateGAPFilterTimes: (state) => {
      state.filterTimes++;
    },

    updateGAPFilterSortingString: (state, action) => {
      state.filterOptionsString = action.payload.filterOptionString;
    },

    updateGAPSortingIcon: (state, action) => {
      let temp: keyof typeof state.sortIconStatus;
      for (temp in state.sortIconStatus) {
        state.sortIconStatus[temp] = Sort.DEFAULT;
      }
      state.sortIconStatus[
        action.payload.type as keyof typeof state.sortIconStatus
      ] = action.payload.mode;
    },
  },
});

export const {
  updateGAPRowsPerPageOptions,
  updateGAPPage,
  updateGAPTotalRecordNum,
  readGAPRecords,
  createGAPRecords,
  updateGAPRecordByCampaignId,
  deleteGAPRecordsById,
  updateGAPFilterState,
  updateGAPFilterSortingOptions,
  updateGAPFilterTimes,
  updateGAPFilterSortingString,
  updateGAPSortingIcon,
  createGAPRecordsByExcel,
} = googleAdsPerformanceSlice.actions;

export default googleAdsPerformanceSlice.reducer;
