import React, { createContext, useContext, useState, useMemo, FC } from 'react';
import { Axios } from './apis/authApis';
export enum actionTypes {
  SignIn = 'SIGNIN',
  SignOut = 'SIGNOUT',
}

type Action = { type: actionTypes; payload: { user: User } };
type User = {
  accessToken?: string;
  username?: string;
  sub?: number;
  isLoggedIn?: boolean;
};

export const AuthContext = createContext<any>({});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: FC<any> = ({ children }) => {
  const [user, setUser] = useState<User>({
    accessToken: sessionStorage.getItem('access_token') || undefined,
    isLoggedIn: !!sessionStorage.getItem('access_token') || false,
  });

  const value = useMemo(() => {
    const dispatchAuthEvent = (action: Action) => {
      switch (action.type) {
        case 'SIGNIN':
          setUser({ ...action.payload.user, isLoggedIn: true });
          sessionStorage.setItem(
            'access_token',
            action.payload.user.accessToken || '',
          );

          return;
        case 'SIGNOUT':
          setUser({ isLoggedIn: false });
          sessionStorage.removeItem('access_token');
          delete Axios.defaults.headers.common['Authorization'];
          return;
        default:
          return;
      }
    };
    return { user, dispatchAuthEvent };
  }, [user]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
