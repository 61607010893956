import { store } from '.';
import { Source } from '../apis/mediaCostApis';
import { Sort, UploadExcelType } from '../components/InterfaceType';
import { updateGASFilterSortingOptions } from './adsSetupSlice';
import { Campaign, Site } from './channelMappingSlice';
import {
  GoogleAdsPerformanceData,
  updateGAPFilterSortingOptions,
} from './googleAdsPerformanceSlice';
import { updateFilterSortingOptions, excelFileType } from './mediaSlice';
export const tableHeader = [
  { header: 'Start Date', type: 'startDate' },
  { header: 'End Date', type: 'endDate' },
  { header: 'Campaign Name', type: 'campaign' },
  { header: 'Site', type: 'site' },
  { header: 'Source', type: 'source' },
  { header: 'Actual Ad Spend', type: 'cost' },
  { header: 'Media Plan Budget', type: 'costWithAc' },
  { header: 'AC Percentage', type: 'costAcPercentage' },
  { header: 'Data Source', type: 'excelFile' },
  { header: 'Campaign Start Date', type: 'campaignStartDate' },
  { header: 'Campaign End Date', type: 'campaignEndDate' },
];
export const updateReduxFilterSorting = (
  value: string | Sort,
  type: string,
  table: UploadExcelType,
) => {
  switch (table) {
    case UploadExcelType.Media:
      let tempMedia = {
        ...store.getState().media.filterSortingOption,
      };
      tempMedia[type as keyof typeof tempMedia] = value.toString();
      store.dispatch(updateFilterSortingOptions({ ...tempMedia }));
      break;
    case UploadExcelType.GoggleAdsPerformance:
      let tempGAP = {
        ...store.getState().googleAdsPerformance.filterSortingOption,
      };
      tempGAP[type as keyof typeof tempGAP] = value.toString();
      store.dispatch(updateGAPFilterSortingOptions({ ...tempGAP }));
      break;

    case UploadExcelType.GoogleAdsSetup:
      let tempGAS = {
        ...store.getState().setup.filterSortingOption,
      };
      tempGAS[type as keyof typeof tempGAP] = value.toString();
      store.dispatch(updateGASFilterSortingOptions({ ...tempGAS }));
      break;

    default:
      break;
  }
};

export const processDateToString = (targetDate: Date) => {
  return new Date(targetDate.getTime() + 86400 * 1000)
    .toISOString()
    .slice(0, 10);
};
export interface CostRecord {
  id: number;
  startDate: string;
  endDate: string;
  cost: number;
  costWithAc: number;
  costAcPercentage: number;
  campaign: Campaign;
  site: Site;
  source: Source;
  excelFile: excelFileType;
}

export interface AdsSetup {
  campaignStatus: string;
  id: number;
  campaignId: string;
  campaignName: string;
  campaignType: string;
  product: string;
  startDate: string;
  endDate: string;
  budget: number;
  excelFile: excelFileType;
}

export const processReturnedData = (rawData: CostRecord[]) => {
  let test = rawData.map((item) => {
    const {
      id,
      startDate,
      endDate,
      campaign,
      site,
      source,
      cost,
      costWithAc,
      costAcPercentage,
      excelFile,
    } = item;
    return {
      ...item,
      id: id,
      startDate: startDate,
      endDate: endDate,
      campaign: campaign,
      site: site,
      source: source,
      cost: cost,
      costWithAc: costWithAc,
      costAcPercentage: costAcPercentage,
      excelFile: excelFile
        ? excelFile
        : { dataType: '', id: NaN, name: 'Manual Input', storagePath: '' },
    };
  });

  return test;
};

export const processSetupData = (rawData: AdsSetup[]) => {
  let data = rawData.map((item) => {
    return {
      ...item,
      excelFile: item.excelFile
        ? item.excelFile
        : { dataType: '', id: NaN, name: 'Manual Input', storagePath: '' },
    };
  });

  return data;
};

export const processPerformanceData = (rawData: GoogleAdsPerformanceData[]) => {
  let test = rawData.map((item) => {
    const {
      id,
      campaignId,
      campaignType,
      product,
      date,
      conversionType,
      conversions,
      impressions,
      clicks,
      videoViews,
      adGroupName,
      adGroupId,
      videoPlayedTo100Percentage,
      viewThroughConversions,
      installs,
      cost,
      excelFile,
    } = item;
    return {
      id: id,
      product: product,
      date: date,
      campaignId: campaignId,
      campaignType: campaignType,
      conversionType: conversionType,
      conversions: conversions,
      impressions: impressions,
      clicks: clicks,
      videoViews: videoViews,
      adGroupName: adGroupName,
      adGroupId: adGroupId ?? 0,
      videoPlayedTo100Percentage: videoPlayedTo100Percentage ?? 0,
      viewThroughConversions: viewThroughConversions ?? 0,
      installs: installs,
      cost: cost,
      excelFile: excelFile
        ? excelFile
        : { dataType: '', id: NaN, name: 'Manual Input', storagePath: '' },
    };
  });

  return test;
};
