import RefreshOutlined from '@mui/icons-material/RefreshOutlined';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Typography,
  TextField,
  Select,
  MenuItem,
  CardActions,
  createTheme,
  ThemeProvider,
  Popover,
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TuneIcon from '@mui/icons-material/Tune';
import { RootState } from '../store';
import { updateReduxFilterSorting } from '../store/utilities';
import { Sort, UploadExcelType } from './InterfaceType';
import {
  updateGAPFilterSortingOptions,
  updateGAPFilterSortingString,
  updateGAPFilterState,
  updateGAPFilterTimes,
} from '../store/googleAdsPerformanceSlice';
import {
  updateFilterTimes,
  updateGASFilterSortingOptions,
  updateGASFilterSortingString,
  updateGASFilterState,
} from '../store/adsSetupSlice';

export const GAPfilterOptions = [
  { header: 'Campaign ID', type: 'campaignId' },
  { header: 'Campaign Type', type: 'campaignType' },
  { header: 'Product', type: 'product' },
  { header: 'Conversion Type', type: 'conversionType' },
  { header: 'Ad Group Name', type: 'adGroupName' },
  { header: 'Ad Group ID', type: 'adGroupId' },
];

export const GASfilterOptions = [
  { header: 'Campaign ID', type: 'campaignId' },
  { header: 'Campaign Type', type: 'campaignType' },
  { header: 'Product', type: 'product' },
];

interface FilterBoxForGoogleAdsProps {
  tableType: UploadExcelType;
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#64c7c7',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#969696',
      contrastText: '#ffffff',
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          justifyContent: 'space-between',
          margin: 8,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 8,
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          minWidth: 200,
          flex: 1,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          width: 'auto',
        },
      },
    },
  },
});

let filterThemeForGA = createTheme(theme, {
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          minWidth: 200,
          flex: 1,
        },
      },
    },
  },
});

const FilterBoxForGoogleAds: React.FC<FilterBoxForGoogleAdsProps> = ({
  tableType,
}) => {
  // let filterOptions = GAPfilterOptions;
  const filter = useSelector((state: RootState) => {
    switch (tableType) {
      case UploadExcelType.GoggleAdsPerformance:
        return state.googleAdsPerformance.filter;

      case UploadExcelType.GoogleAdsSetup:
        // filterOptions = GASfilterOptions;
        return state.setup.filter;

      default:
        break;
    }
  });
  const filterSortingOption = useSelector((state: RootState) => {
    switch (tableType) {
      case UploadExcelType.GoggleAdsPerformance:
        return state.googleAdsPerformance.filterSortingOption;

      case UploadExcelType.GoogleAdsSetup:
        return state.setup.filterSortingOption;

      default:
        break;
    }
  });

  const [filterButton, setFilterButton] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleFilterOptionsForGoogleAds = () => {
    if (filterSortingOption) {
      if (filterSortingOption.filterTarget && filterSortingOption.filterValue) {
        let filterOptionsString = `${filterSortingOption.filterTarget}: ${filterSortingOption.filterValue}`;
        switch (tableType) {
          case UploadExcelType.GoggleAdsPerformance:
            dispatch(updateGAPFilterState({ state: true }));
            dispatch(updateGAPFilterTimes());
            dispatch(
              updateGAPFilterSortingString({
                filterOptionString: filterOptionsString,
              }),
            );
            break;
          case UploadExcelType.GoogleAdsSetup:
            dispatch(updateGASFilterState({ state: true }));
            dispatch(updateFilterTimes());
            dispatch(
              updateGASFilterSortingString({
                filterOptionString: filterOptionsString,
              }),
            );
            break;

          default:
            break;
        }

        setFilterButton(false);
      }
    }
    setAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickFilterBox = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterButton(!filterButton);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const handleResetFilter = () => {
    setFilterButton(false);

    if (tableType === UploadExcelType.GoggleAdsPerformance) {
      dispatch(updateGAPFilterState({ state: false }));
      dispatch(updateGAPFilterSortingString({ filterOptionString: '' }));
      dispatch(
        updateGAPFilterSortingOptions({
          filterTarget: '',
          filterValue: '',
          sortingMethod: Sort.ASC,
          sortingTarget: '',
        }),
      );
    } else if (tableType === UploadExcelType.GoogleAdsSetup) {
      dispatch(updateGASFilterState({ state: false }));
      dispatch(updateGASFilterSortingString({ filterOptionString: '' }));
      dispatch(
        updateGASFilterSortingOptions({
          startDate: '',
          endDate: '',
          filterTarget: '',
          filterValue: '',
          sortingMethod: Sort.ASC,
          sortingTarget: '',
        }),
      );
    }

    setAnchorEl(null);
  };

  return (
    <div className="flex flex-row min-w-[230px] gap-2">
      <>
        <Button
          variant="contained"
          color={filter === true ? 'primary' : 'secondary'}
          startIcon={<TuneIcon />}
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickFilterBox}
        >
          FILTER
        </Button>
        <ThemeProvider theme={filterThemeForGA}>
          <Popover
            anchorEl={anchorEl}
            anchorReference="anchorEl"
            open={open}
            onClose={handleCloseFilter}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Card>
              <CardContent>
                <Typography>Search</Typography>
                <FormControl size="small">
                  <Select
                    size="small"
                    displayEmpty
                    value={filterSortingOption?.filterTarget}
                    onChange={(e) => {
                      updateReduxFilterSorting(
                        e.target.value as string,
                        'filterTarget',
                        tableType,
                      );
                    }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return 'Select Column Name';
                      }
                      return selected;
                    }}
                  >
                    {tableType === UploadExcelType.GoggleAdsPerformance
                      ? GAPfilterOptions.map((item) => (
                          <MenuItem
                            value={item.header}
                            key={`select-${item.type}`}
                          >
                            {item.header}
                          </MenuItem>
                        ))
                      : GASfilterOptions.map((item) => (
                          <MenuItem
                            value={item.header}
                            key={`select-${item.type}`}
                          >
                            {item.header}
                          </MenuItem>
                        ))}
                  </Select>
                  <TextField
                    fullWidth
                    value={filterSortingOption?.filterValue}
                    placeholder="Input filter value"
                    onChange={(e) =>
                      updateReduxFilterSorting(
                        e.target.value as string,
                        'filterValue',
                        tableType,
                      )
                    }
                    label="Keyword"
                    size="small"
                  />
                </FormControl>
              </CardContent>

              <CardActions>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setFilterButton(false);
                    setAnchorEl(null);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleFilterOptionsForGoogleAds}
                >
                  FILTER
                </Button>
              </CardActions>
            </Card>
          </Popover>
        </ThemeProvider>
      </>

      {filter && (
        <>
          <Button
            variant="contained"
            color="inherit"
            startIcon={<RefreshOutlined />}
            onClick={handleResetFilter}
            className="mx-[10px]"
          >
            RESET
          </Button>
        </>
      )}
    </div>
  );
};

export default FilterBoxForGoogleAds;
