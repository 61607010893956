import { forwardRef } from 'react';

const Footer = forwardRef<HTMLDivElement>((props, ref): JSX.Element => {
  return (
    <footer
      {...props}
      ref={ref}
      className="w-full bg-gray-800 p-4 text-center text-white text-xs"
    >
      © FiveStones, a Numiracle Group company,
      <br className="block md:hidden" /> all rights reserved.
    </footer>
  );
});

export default Footer;
