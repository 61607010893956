import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Sort, UploadExcelType } from './InterfaceType';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

export interface SortIconProp {
  type: string;
  mode: Sort;
  clickHandler: (type: string, mode: string) => void;
  tableType: UploadExcelType;
  showName: string;
}

const SortIconStatus = ({
  type,
  mode,
  clickHandler,
  tableType,
  showName,
}: SortIconProp) => {
  const property = useSelector((state: RootState) => {
    if (tableType === UploadExcelType.Media) {
      return state.media.sortIconStatus;
    } else if (tableType === UploadExcelType.GoogleAdsSetup) {
      return state.setup.sortIconStatus;
    } else return state.googleAdsPerformance.sortIconStatus;
  });

  // const modeList = ['', 'ASC', 'DESC'];

  const sortHandler = () => {
    if (mode === Sort.ASC) {
      clickHandler(type, 'DESC');
    } else if (mode === Sort.DESC) {
      clickHandler('', '');
    } else clickHandler(type, 'ASC');
  };

  return (
    <button
      onClick={sortHandler}
      className="py-4 text-black text-left bg-transparent border-0 cursor-pointer hover:text-[#64C7C7] pl-0 font-semibold text-sm font-sans"
    >
      {showName}

      <span className="items-center">
        {property[type as keyof typeof property] === '' ? null : property[
            type as keyof typeof property
          ] === 'ASC' ? (
          <ArrowDownwardIcon
            style={{ width: 20, height: 20 }}
            className="align-middle items-center"
          />
        ) : (
          <ArrowUpwardIcon
            style={{ width: 20, height: 20 }}
            className="align-middle items-center"
          />
        )}
      </span>
    </button>
  );
};

export default SortIconStatus;
