import { FC, useEffect } from 'react';
import { Axios, getCSRFToken } from '../apis/authApis';
import { useDispatch } from 'react-redux';
import { logout } from '../store/userSlice';
import { AxiosError } from 'axios';

const WithAxios: FC<any> = ({ children }) => {
  const dispatch = useDispatch();
  if (sessionStorage.getItem('access_token') !== '') {
    //set default Authorization header for all requests
    Axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${sessionStorage.getItem('access_token')}`;
  }

  useEffect(() => {
    Axios.interceptors.response.use(
      (response) => {
        // if (response.config.url !== response.config.baseURL + '/v1/csrf') {
        //   getCSRFToken();
        // }
        return response;
      },
      (error: AxiosError) => {
        const status = error.response?.status;
        // getCSRFToken();

        switch (status) {
          // authentication (token related issues)
          case 401: {
            // throw error to handler in Login component
            if (error.config.url === '/v1/auth/login') throw error;
            else {
              //logout user if status code 401: session timeout
              dispatch(logout());
              getCSRFToken();
            }
            return Promise.reject(console.error('response error: 401'));
          }
          // forbidden (permission related issues)
          case 403: {
            return Promise.reject(console.error('response error: 403'));
          }
          // bad request
          case 400: {
            // Modal handle 400 from this api call
            if (
              error.config.url?.includes('upload-excel-file') ||
              error.config.url === 'v1/user/me' ||
              error.config.method === 'post' ||
              error.config.method === 'patch'
            )
              throw error;
            else {
              return Promise.reject(console.error('response error: 400'));
            }
          }
          // not found
          case 404: {
            return Promise.reject(console.error('response error: 404'));
          }
          default: {
            return Promise.reject(error);
          }
        }
      },
    );
  }, [dispatch]);

  return children;
};

export default WithAxios;
