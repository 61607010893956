import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import Accordion from '@mui/material/Accordion';
import {
  AccordionDetails,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  createTheme,
  ThemeProvider,
  TablePagination,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import { MappingModalConfig, ModalMode } from '../components/InterfaceType';
import MappingModal from '../components/MappingModal';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import {
  updateMapping,
  Mapping,
  Site,
  updatePage,
  updateRowsPerPage,
} from '../store/channelMappingSlice';
import { getChannelsByPage } from '../apis/mappingApis';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import PageTitle from '../components/PageTitle';
import Section from '../components/Section';
import RefreshOutlined from '@mui/icons-material/RefreshOutlined';

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0)',
  flexDirection: 'row-reverse',
}));

export const theme = createTheme({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            ':before': {
              opacity: 1,
            },
          },
        },
      },
    },
  },
});

const ChannelMapping = () => {
  const [mappingPage, setMappingPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState(1);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isMappingModalOpen, setIsMappingModalOpen] = useState<boolean>(false);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [searchChannel, setSearchChannel] = useState<string>('');
  const [searchChannelString, setSearchChannelString] = useState<string>('');

  const [options, setOptions] = useState<MappingModalConfig>({
    mode: ModalMode.CreateMapping,
    isOpen: false,
    setIsOpen: setIsOpen,
    mappingData: {
      channel: '',
      sites: [],
    },
  });

  const channelMapping = useSelector(
    (state: RootState) => state.mapping.mapping,
  );

  const currentPage = useSelector(
    (state: RootState) => state.mapping.currentPage,
  );
  const limit = useSelector((state: RootState) => state.mapping.limit);

  const total = useSelector((state: RootState) => state.mapping.total);
  const dispatch = useDispatch();

  useEffect(() => {
    const getMapping = async () => {
      let mappings = await getChannelsByPage({
        query: searchChannel,
        page: currentPage + 1,
        limit: limit,
      });

      setPageCount(Math.ceil(mappings.total / limit) || 1);

      dispatch(updateMapping({ ...mappings, currentPage: mappingPage }));
    };
    getMapping();
  }, [total, currentPage, limit]);

  const handleSearchChannelChange = (e: React.ChangeEvent) => {
    setSearchChannel((e.target as HTMLInputElement).value);
  };
  function onEditClick(
    event: React.MouseEvent<HTMLElement>,
    channelMappingData: Mapping,
  ) {
    event.stopPropagation();
    setOptions((options) => ({
      ...options,
      mode: ModalMode.EditMapping,
      mappingData: {
        id: channelMappingData.id,
        channel: channelMappingData.name,
        sites: channelMappingData.sites,
      },
      setIsOpen: setIsMappingModalOpen,
      isOpen: true,
    }));
    setIsMappingModalOpen(true);
  }
  function onDeleteClick(
    event: React.MouseEvent<HTMLElement>,
    channelMappingData: Mapping,
  ) {
    event.stopPropagation();
    setOptions((options) => ({
      ...options,
      mode: ModalMode.DeleteMappingByChannel,
      mappingData: {
        id: channelMappingData.id,
        channel: channelMappingData.name,
        sites: channelMappingData.sites,
      },
      isOpen: true,
      setIsOpen: setIsMappingModalOpen,
    }));

    setIsMappingModalOpen(true);
  }

  const handleCreateMapping = () => {
    setOptions((options) => ({
      ...options,
      mode: ModalMode.CreateMapping,
      mappingData: {
        channel: '',
        sites: [],
      },
      setIsOpen: setIsMappingModalOpen,
      isOpen: true,
    }));
    setIsMappingModalOpen(true);
  };

  const handleDeleteSite = (
    e: React.MouseEvent<HTMLElement>,
    channelMappingData: Mapping,
    channelMappingSitesData: Site,
  ) => {
    setOptions((options) => ({
      ...options,
      mode: ModalMode.DeleteMappingById,
      mappingData: {
        channel: channelMappingData.name,
        sites: [channelMappingSitesData],
        id: channelMappingData.id,
      },
      isOpen: true,
      setIsOpen: setIsMappingModalOpen,
    }));
    setIsMappingModalOpen(true);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && searchChannel) {
      event.preventDefault();
      channelSearching();
      setIsSearch(true);
      setSearchChannelString(searchChannel);
    }
  };

  const channelSearching = () => {
    if (searchChannel) {
      setSearchChannelString(searchChannel);
      const getMapping = async () => {
        let mappings = await getChannelsByPage({
          query: searchChannel,
          page: 1,
          limit: limit,
        });
        let page = Math.ceil(mappings.total / limit);
        if (isNaN(page)) {
          setPageCount(1);
        } else setPageCount(page);

        dispatch(updateMapping(mappings));
      };
      getMapping();
      setIsSearch(true);
    }
  };

  const resetChannelSearching = () => {
    const getMapping = async () => {
      let mappings = await getChannelsByPage({
        query: '',
        page: 1,
        limit: limit,
      });
      let page = Math.ceil(mappings.total / limit);
      if (isNaN(page)) {
        setPageCount(1);
      } else setPageCount(page);

      dispatch(updateMapping(mappings));
    };
    getMapping();
    setSearchChannel('');
    setIsSearch(false);
  };

  return (
    <div className="channelMapping">
      <PageTitle>Channel Mapping</PageTitle>
      <Section>
        <Container maxWidth="xl">
          <div className="flex flex-wrap gap-4">
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={handleCreateMapping}
            >
              NEW MAPPING
            </Button>
            <TextField
              id="searchChannelName"
              variant="outlined"
              value={searchChannel}
              onChange={(e) => handleSearchChannelChange(e)}
              onKeyDown={handleKeyDown}
              placeholder="Search by Channel Name"
              fullWidth
              size="small"
              className="max-w-[270px] text-sm h-9"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={channelSearching}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {isSearch && (
              <Button
                variant="contained"
                color="inherit"
                startIcon={<RefreshOutlined />}
                onClick={resetChannelSearching}
              >
                RESET
              </Button>
            )}
          </div>
        </Container>
      </Section>
      <Section>
        <Container maxWidth="xl">
          {isSearch && (
            <span className="text-gray-400">
              Search Channel: {searchChannelString}
            </span>
          )}
        </Container>
      </Section>
      <Section>
        <Container maxWidth="xl">
          {channelMapping.length === 0 ? (
            'No result found'
          ) : (
            <>
              {channelMapping.map((channel) => (
                <ThemeProvider theme={theme} key={channel.id}>
                  <Accordion
                    elevation={0}
                    sx={{
                      border: 0,
                      boxShadow: 0,
                      ml: -1,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="border-0"
                    >
                      <div className="flex items-center flex-wrap ml-[23px] w-full">
                        {channel.name}
                      </div>
                      <IconButton
                        onClick={(e) => onEditClick(e, channel)}
                        aria-label="edit"
                        className="flex justify-end"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={(e) => onDeleteClick(e, channel)}
                        aria-label="delete"
                        className="flex justify-end mr-[48px]"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer className="bg-[#64C7C7]/[.05] w-auto flex">
                        {channel.sites.length > 0 ? (
                          <Table className="flex-1 mx-8">
                            <TableHead>
                              <TableRow sx={{ mx: 2 }}>
                                <TableCell style={{ width: '10%' }}>
                                  Site ID
                                </TableCell>
                                <TableCell style={{ width: '70%' }}>
                                  Site Name
                                </TableCell>
                                <TableCell style={{ width: '20%' }}></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {channel.sites.map(
                                (channelSites: Site, index) => (
                                  <TableRow
                                    key={channelSites.id}
                                    sx={{
                                      '&:last-child td, &:last-child th': {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell style={{ width: '10%' }}>
                                      {channelSites.id}
                                    </TableCell>
                                    <TableCell style={{ width: '70%' }}>
                                      {channelSites.name}
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      style={{ width: '20%' }}
                                    >
                                      <IconButton
                                        aria-label="delete"
                                        onClick={(e) =>
                                          handleDeleteSite(
                                            e,
                                            channel,
                                            channelSites,
                                          )
                                        }
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ),
                              )}
                            </TableBody>
                          </Table>
                        ) : (
                          <Box className="flex-1 m-8">
                            This channel does not contain any site
                          </Box>
                        )}
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                </ThemeProvider>
              ))}
              <TablePagination
                className="justify-center flex"
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={total}
                rowsPerPage={limit}
                page={currentPage}
                onPageChange={(e, page) => dispatch(updatePage({ page }))}
                onRowsPerPageChange={(e) => {
                  dispatch(
                    updateRowsPerPage({ limit: parseInt(e.target.value) }),
                  );
                }}
              />
            </>
          )}
        </Container>
      </Section>

      {isMappingModalOpen && <MappingModal {...options} />}
    </div>
  );
};

export default ChannelMapping;
