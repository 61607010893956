import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { signin } from '../apis/authApis';
import { AxiosError } from 'axios';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { login, selectUser } from '../store/userSlice';
import Section from '../components/Section'

type LocationState = {
  from: {
    pathname: string;
  };
};
function Login(): JSX.Element {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const [errorMsg, setErrorMsg] = useState<string>();

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationState;

  const handleSubmit = async (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault();
    try {
      const user = await signin({
        username: username,
        password: password,
      });

      if (user) {
        dispatch(login({ ...user }));
        navigate(state?.from.pathname || '/media-cost');
      } else {
        setErrorMsg('Login Failed: Invalid Username or Password');
      }
    } catch (err: unknown) {
      if (err instanceof AxiosError && err.response?.status === 401) {
        setErrorMsg('Login Failed: Invalid Username or Password');
      }
    }
  };

  if (user && user.isLoggedIn) return <Navigate to="/media-cost" replace />;

  return (
    <div className="login">
      <Section className="w-full h-screen flex items-center justify-center">
        <div className="modal w-full max-w-[580px] bg-white shadow-xl p-8 flex flex-col gap-8">
          <div className="modal-title">Login</div>
          <form
            onSubmit={(e) => handleSubmit(e)}
            className="flex flex-col gap-8"
          >
            <div className="flex">
              <TextField
                id="username"
                label="Username"
                variant="outlined"
                sx={{ width: '100%' }}
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="flex">
              <TextField
                id="password"
                label="Password"
                required
                type={'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                sx={{ width: '100%' }}
              />
            </div>
            {errorMsg && <Alert severity="error">{errorMsg}</Alert>}

            <Button color="primary" variant="contained" type="submit">
              SIGN IN
            </Button>
            {/* <Link href="/reset-password">Forgot password?</Link> */}
          </form>
        </div>
      </Section>
    </div>
  );
}

export default Login;
