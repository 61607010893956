import Container from '@mui/material/Container';
import NotFoundImage from '../assets/img/404.svg';
import Section from '../components/Section';

function NotFound() {
  return (
    <Section className="h-screen flex items-center justify-center">
      <Container>
        <div className="flex">
          <div className="w-full flex items-center justify-center text-center">
            <div>
              <img
                src={NotFoundImage}
                className="img-fluid w-75"
                alt="404 not found"
              />
              <h1 className="mt-5">
                Page not <span className="fw-bolder">found</span>
              </h1>
              <p className="lead my-4">
                Oops! Looks like you followed a bad link.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
}

export default NotFound;
