import React, { useMemo, useState } from 'react';
import { TextField, Button, Alert, Autocomplete } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  createAdsSetup,
  updateAdsSetup,
  deleteAdsSetup,
} from '../apis/adsSetupApis';
import {
  GoogleAdsSetupData,
  AdsSetupModalConfig,
  ModalMode,
} from './InterfaceType';
import { useDispatch } from 'react-redux';
import {
  createMediaRecords,
  deleteMediaRecordsById,
  updateMediaRecordsById,
} from '../store/adsSetupSlice';
import CustomModal from './CustomModal';
import CircularProgress from '@mui/material/CircularProgress';

interface Option {
  label: string;
}

const campaignTypesOptions: Option[] = [
  { label: 'search' },
  { label: 'display' },
  { label: 'video' },
  { label: 'shopping' },
  { label: 'app' },
  { label: 'local' },
  { label: 'smart' },
  { label: 'performance' },
  { label: 'max' },
];

const campaignStatusOptions: Option[] = [
  { label: 'Eligible' },
  { label: 'Eligible (limited)' },
  { label: 'not eligible' },
  { label: 'paused' },
  { label: 'removed' },
  { label: 'pending' },
  { label: 'ended' },
];

const AdsSetupModal: React.FC<AdsSetupModalConfig> = ({
  mode,
  setIsOpen,
  adsSetupData,
}) => {
  const [newGoogleAdsSetupData, setNewGoogleAdsSetupData] =
    useState<GoogleAdsSetupData>(adsSetupData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmittedOnce, setIsSubmittedOnce] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const dispatch = useDispatch();

  const isFieldNonEmpty = (obj: GoogleAdsSetupData) => {
    if (mode === 'CREATE_GAS' || mode === 'EDIT_GAS')
      return Object.entries(obj).every(([key, value]) => {
        if (typeof value === 'number') {
          return !isNaN(value);
        } else if (typeof value === 'object' || typeof value === 'string') {
          return value !== null && value !== undefined && value !== '';
        }
        return true;
      });
    else {
      return true;
    }
  };

  const buttonText = useMemo(() => {
    if (mode === 'CREATE_GAS') return 'Create';
    else if (mode === 'EDIT_GAS') return 'Save';
    else if (mode === 'DELETE_GAS') return 'Confirm';
    return '';
  }, [mode]);

  const titleText = useMemo(() => {
    if (mode === 'CREATE_GAS') return 'New Ads Setup';
    else if (mode === 'EDIT_GAS') return 'Edit Ads Setup';
    else if (mode === 'DELETE_GAS') return 'Delete Ads Setup';
    return '';
  }, [mode]);

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): Promise<void> => {
    e.preventDefault();
    setIsSubmittedOnce(true);
    setAlertMessage('');

    if (
      !isFieldNonEmpty(newGoogleAdsSetupData) ||
      isNaN(Number(newGoogleAdsSetupData.startDate)) ||
      isNaN(Number(newGoogleAdsSetupData.endDate))
    ) {
      setAlertMessage('Please fill all required fields');
      return;
    }

    try {
      setIsLoading(true);
      switch (mode) {
        case ModalMode.CreateGAS:
          let createdRecord = await createAdsSetup(newGoogleAdsSetupData);

          if (createdRecord) dispatch(createMediaRecords({ ...createdRecord }));
          break;
        case ModalMode.EditGAS:
          let updatedRecord = await updateAdsSetup(newGoogleAdsSetupData);

          if (updatedRecord)
            dispatch(updateMediaRecordsById({ ...updatedRecord }));

          break;
        case ModalMode.DeleteGAS:
          let status = await deleteAdsSetup(newGoogleAdsSetupData);

          if (status === 200) {
            dispatch(deleteMediaRecordsById({ id: newGoogleAdsSetupData.id }));
          }
          break;
        default:
          break;
      }
      setIsLoading(false);
      setIsOpen(false);
    } catch (err: any) {
      setIsLoading(false);

      handleSetAlertMessage(err);
    }
  };

  const handleSetAlertMessage = (err: any) => {
    if (Array.isArray(err.response.data.message))
      setAlertMessage(err.response.data.message[0]);
    else {
      setAlertMessage(err.response.data.message);
    }
  };

  const isDateRangeValid = (startDate: any, endDate: any) => {
    if (startDate && endDate) {
      const date1 = new Date(startDate);
      const date2 = new Date(endDate);
      return date1 <= date2;
    }
    return false;
  };

  return (
    <CustomModal
      top={titleText}
      middle={
        <>
          {mode === 'EDIT_GAS' || mode === 'CREATE_GAS' ? (
            <>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start Date"
                  value={newGoogleAdsSetupData!.startDate}
                  inputFormat="yyyy-MM-dd"
                  onChange={(newValue) => {
                    setNewGoogleAdsSetupData((data) => ({
                      ...data,

                      startDate: newValue,
                    }));
                    if (
                      alertMessage === 'startDate must be on or before endDate'
                    )
                      setAlertMessage('');
                  }}
                  maxDate={
                    newGoogleAdsSetupData.endDate
                      ? newGoogleAdsSetupData.endDate
                      : undefined
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      helperText={
                        (newGoogleAdsSetupData.startDate === null ||
                          isNaN(Number(newGoogleAdsSetupData.startDate))) &&
                        isSubmittedOnce
                          ? 'Invalid date'
                          : null
                      }
                      error={
                        (newGoogleAdsSetupData.startDate === null ||
                          !isDateRangeValid(
                            newGoogleAdsSetupData.startDate,
                            newGoogleAdsSetupData.endDate,
                          ) ||
                          isNaN(Number(newGoogleAdsSetupData.startDate))) &&
                        isSubmittedOnce
                      }
                    />
                  )}
                />
                <DatePicker
                  label="End Date"
                  inputFormat="yyyy-MM-dd"
                  value={newGoogleAdsSetupData!.endDate}
                  onChange={(newValue) => {
                    setNewGoogleAdsSetupData((data) => ({
                      ...data,
                      endDate: newValue,
                    }));
                    if (
                      alertMessage === 'startDate must be on or before endDate'
                    )
                      setAlertMessage('');
                  }}
                  minDate={
                    newGoogleAdsSetupData.startDate
                      ? newGoogleAdsSetupData.startDate
                      : undefined
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      helperText={
                        (newGoogleAdsSetupData.endDate === null ||
                          isNaN(Number(newGoogleAdsSetupData.endDate))) &&
                        isSubmittedOnce
                          ? 'Invalid date'
                          : null
                      }
                      error={
                        (newGoogleAdsSetupData.endDate === null ||
                          !isDateRangeValid(
                            newGoogleAdsSetupData.startDate,
                            newGoogleAdsSetupData.endDate,
                          ) ||
                          isNaN(Number(newGoogleAdsSetupData.endDate))) &&
                        isSubmittedOnce
                      }
                    />
                  )}
                />
              </LocalizationProvider>
              <TextField
                required
                type="text"
                id="campaign-id"
                label="Campaign ID"
                value={newGoogleAdsSetupData?.campaignId || ''}
                onChange={(e) => {
                  setNewGoogleAdsSetupData((data) => ({
                    ...data,
                    campaignId: e.target.value || null,
                  }));
                }}
                error={
                  newGoogleAdsSetupData.campaignId === null && isSubmittedOnce
                }
              />
              <TextField
                required
                type="text"
                id="campaign-name"
                label="Campaign Name"
                value={newGoogleAdsSetupData?.campaignName || ''}
                onChange={(e) => {
                  setNewGoogleAdsSetupData((data) => ({
                    ...data,
                    campaignName: e.target.value || null,
                  }));
                }}
                error={
                  newGoogleAdsSetupData.campaignName === null && isSubmittedOnce
                }
              />

              <Autocomplete
                id="campaign-type"
                options={campaignTypesOptions}
                getOptionLabel={(option) => option.label}
                onChange={(e, option) => {
                  setNewGoogleAdsSetupData((data) => ({
                    ...data,

                    campaignType: option && option.label,
                  }));
                }}
                defaultValue={
                  adsSetupData.campaignType
                    ? {
                        label: adsSetupData.campaignType,
                      }
                    : undefined
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Campaign Type"
                    error={
                      newGoogleAdsSetupData.campaignType === null &&
                      isSubmittedOnce
                    }
                  />
                )}
              />

              <TextField
                required
                type="text"
                id="product"
                label="Product"
                value={newGoogleAdsSetupData?.product || ''}
                onChange={(e) => {
                  setNewGoogleAdsSetupData((data) => ({
                    ...data,
                    product: e.target.value,
                  }));
                }}
                error={
                  newGoogleAdsSetupData.product === null && isSubmittedOnce
                }
                helperText="Please put the value according to the naming convention: [YYYYMM]_[Product]"
              />

              <TextField
                required
                type="number"
                id="budget"
                label="Budget (HKD)"
                value={
                  Number(newGoogleAdsSetupData?.budget) >= 0
                    ? Number(newGoogleAdsSetupData?.budget)
                    : ''
                }
                onChange={(e) => {
                  setNewGoogleAdsSetupData((data) => ({
                    ...data,
                    budget: parseFloat(e.target.value),
                  }));
                }}
                error={isNaN(newGoogleAdsSetupData.budget) && isSubmittedOnce}

                // error={
                //   (newGoogleAdsSetupData.budget === null ||
                //     Number(newGoogleAdsSetupData.budget) < 0 ||
                //     isNaN(newGoogleAdsSetupData.budget)) &&
                //   isSubmittedOnce
                // }
              />

              <Autocomplete
                id="campaign-status"
                options={campaignStatusOptions}
                getOptionLabel={(option) => option.label}
                onChange={(e, option) => {
                  setNewGoogleAdsSetupData((data) => ({
                    ...data,

                    campaignStatus: option && option.label,
                  }));
                }}
                defaultValue={
                  adsSetupData.campaignStatus
                    ? {
                        label: adsSetupData.campaignStatus,
                      }
                    : undefined
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Campaign Status"
                    error={
                      (newGoogleAdsSetupData.campaignStatus === null ||
                        newGoogleAdsSetupData.campaignStatus === '') &&
                      isSubmittedOnce
                    }
                  />
                )}
              />
            </>
          ) : (
            ''
          )}
          {mode === 'DELETE_GAS' && (
            <div>Remove Ads Setup #{newGoogleAdsSetupData.id}</div>
          )}
          {alertMessage !== '' && (
            <Alert severity="error">{alertMessage}</Alert>
          )}
        </>
      }
      bottom={
        <>
          <Button
            variant="contained"
            sx={{ marginRight: '10px' }}
            type="submit"
            onClick={(e) => handleSubmit(e)}
            disabled={isLoading}
          >
            {buttonText}
            {isLoading && (
              <CircularProgress
                style={{
                  color: 'white',
                  width: '15px',
                  height: '15px',
                  marginLeft: '5px',
                }}
              />
            )}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
        </>
      }
    ></CustomModal>
  );
};

export default AdsSetupModal;
