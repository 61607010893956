import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import '../assets/css/header.css';
import HeaderMenu from '../components/HeaderMenu';
import MobileMenu from '../components/MobileMenu';
import fsLogo from '../assets/img/FS-logo.svg';
import Container from '@mui/material/Container';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout, selectUser } from '../store/userSlice';
import { Avatar, Menu, MenuItem } from '@mui/material';

const Header = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const setHeaderHeightHandler = () => {
    setTimeout(() => {
      if (ref.current && ref.current.clientHeight) {
        const height = ref.current.clientHeight;
        setHeight(height);
      }
    }, 100);
  };

  useLayoutEffect(() => {
    setHeaderHeightHandler();
  });

  useEffect(() => {
    window.addEventListener('resize', setHeaderHeightHandler);
    return () => window.removeEventListener('resize', setHeaderHeightHandler);
  });

  const handleSignOut = () => {
    dispatch(logout());
    navigate('/login', { replace: true });
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <header className="static shadow-lg">
      <Container maxWidth="xl" ref={ref}>
        <div className="flex items-center min-h-[64px] gap-4">
          {user && user.isLoggedIn && (
            <MobileMenu className="flex-none" headerHeight={height}>
              <HeaderMenu />
            </MobileMenu>
          )}
          <Link to="/" className="no-underline flex-1 flex items-center">
            <div className="w-[100%] max-w-[125px]">
              <img src={fsLogo} alt="FiveStones Logo" />
            </div>
            <div className="w-px h-4 bg-gray-500 mx-3 lg:mx-4"></div>
            <div className="header-projectName text-gray-800">
              CCBA Data Visualization
            </div>
          </Link>
          {user && user.isLoggedIn && (
            <div className="flex justify-end items-center gap-4">
              <div className="hidden lg:block">
                <HeaderMenu />
              </div>

              <Avatar
                className="w-6 h-6 cursor-pointer"
                onClick={(e) => handleClick(e)}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              ></Avatar>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    navigate('/change-password');
                    handleClose();
                  }}
                >
                  Change Password
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleSignOut();
                    setAnchorEl(null);
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </div>
          )}
        </div>
      </Container>
    </header>
  );
};

export default Header;
