import Container from '@mui/material/Container';
import React from 'react';
import Section from './Section';

type Props = {
  children: React.ReactNode;
};

function PageTitle({ children }: Props) {
  return (
    <Section>
      <Container maxWidth="xl">
        <h1 className="text-2xl text-gray-800 ">{children}</h1>
      </Container>
    </Section>
  );
}

export default PageTitle;
