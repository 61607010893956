import React, { FC } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from '../store/userSlice';
import Layout from '../components/Layout';
import MediaCost from '../pages/MediaCost';
import NotFound from '../pages/NotFound';
import Login from '../pages/Login';
import ChannelMapping from '../pages/ChannelMapping';
import ResetPassword from '../pages/ResetPassword';
import GoogleAdsSetup from '../pages/GoogleAdsSetup';
import GoogleAdsPerformance from '../pages/GoogleAdsPerformance';


const Router = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <MediaCost />
            </PrivateRoute>
          }
        />
        <Route
          path="/change-password"
          element={
            <PrivateRoute>
              <ResetPassword />
            </PrivateRoute>
          }
        />
        <Route
          path="/media-cost"
          element={
            <PrivateRoute>
              <MediaCost />
            </PrivateRoute>
          }
        />
        <Route
          path="/channel-mapping"
          element={
            <PrivateRoute>
              <ChannelMapping />
            </PrivateRoute>
          }
        />
        <Route
          path="/google-ads-setup"
          element={
            <PrivateRoute>
              <GoogleAdsSetup/>
            </PrivateRoute>
          }
        />
        <Route
          path="/google-ads-performance"
          element={
            <PrivateRoute>
              <GoogleAdsPerformance/>
            </PrivateRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

const PrivateRoute: FC<any> = ({ children, ...rest }) => {
  const user = useSelector(selectUser);

  let location = useLocation();
  return user.isLoggedIn ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default Router;
