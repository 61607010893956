import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Container from '@mui/material/Container';
import Add from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import type { } from '@mui/x-date-pickers/themeAugmentation';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {
  ModalMode,
  Sort,
  UploadExcelType,
  MediaModalConfig,
} from '../components/InterfaceType';
import MediaModal from '../components/MediaModal';
import ExcelModal from '../components/ExcelModal';
import { RootState } from '../store';
import {
  changeTotalRecordNum,
  updateMediaRecords,
  updateRowsPerPageOptions,
  updatePage,
  updateSortingIcon,
} from '../store/mediaSlice';
import FilterBox from '../components/FilterBox';
import {
  CostRecord,
  processReturnedData,
  updateReduxFilterSorting,
} from '../store/utilities';
import { getMediaRecord } from '../apis/mediaCostApis';
import { getExcelTemplate } from '../apis/mediaCostApis';
import SortIconStatus from '../components/SortIconStatus';
import PageTitle from '../components/PageTitle';
import Section from '../components/Section';
import * as d3 from 'd3-format';
import DownloadIcon from '@mui/icons-material/Download';

const MediaCost = () => {
  const media = useSelector((state: RootState) => state.media);

  const {
    records: test,
    filterSortingOption,
    totalRecords,
    filter: isFilterSet,
    filterTimes,
    sortIconStatus,
    rowsPerPage,
    page,
    filterOptionsString: filterSortingString,
  } = media;

  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isExcelModalOpen, setIsExcelModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const getRecords = async () => {
      let records = await getMediaRecord({
        startDate: filterSortingOption.startDate,
        endDate: filterSortingOption.endDate,
        filterTarget: filterSortingOption.filterTarget,
        filterValue: filterSortingOption.filterValue,
        sortingTarget: filterSortingOption.sortingTarget,
        sortingMethod: filterSortingOption.sortingMethod,
        limit: rowsPerPage,
        page: page + 1,
      });

      let processed = processReturnedData(records.results);
      dispatch(changeTotalRecordNum(records.total));
      dispatch(updateMediaRecords(processed));
    };
    getRecords();
  }, [
    page,
    rowsPerPage,
    sortIconStatus,
    isFilterSet,
    filterTimes,
    totalRecords,
  ]);

  const [options, setOptions] = useState<MediaModalConfig>({
    mode: ModalMode.CreateMapping,
    isOpen: false,
    setIsOpen: setIsOpen,
    mediaCostData: {
      startDate: null,
      endDate: null,
      campaign: null,
      site: null,
      source: null,
      cost: 0,
      costWithAc: 0,
      costAcPercentage: 0,
    },
  });
  const handleChangePage = (event: unknown, newPage: number) => {
    dispatch(updatePage({ page: newPage }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(
      updateRowsPerPageOptions({
        rowsPerPage: parseInt(event.target.value, 10),
      }),
    );
  };

  const sortHandler = (type: string, mode: string) => {

    updateReduxFilterSorting(type, 'sortingTarget', UploadExcelType.Media);
    updateReduxFilterSorting(mode, 'sortingMethod', UploadExcelType.Media);

    if (!type) dispatch(updateSortingIcon({ type: '', mode: Sort.DEFAULT }));

    mode === 'DESC'
      ? dispatch(updateSortingIcon({ type: type, mode: Sort.DESC }))
      : dispatch(updateSortingIcon({ type: type, mode: Sort.ASC }));
  };

  const handleEditDeleteMedia = (row: CostRecord, modalMode: ModalMode) => {
    setOptions((options) => ({
      ...options,
      mode: modalMode,
      mediaCostData: {
        id: row.id,
        startDate: new Date(row.startDate),
        endDate: new Date(row.endDate),
        campaign: row.campaign,
        site: row.site,
        source: row.source,
        cost: row.cost,
        costWithAc: row.costWithAc,
        costAcPercentage: row.costAcPercentage * 100,
        excelFileId: row.excelFile,
      },
      isOpen: true,
      setIsOpen: setIsOpen,
    }));
    setIsOpen(true);
  };

  const handleCreateMedia = () => {
    setOptions((options) => ({
      ...options,
      mode: ModalMode.CreateMedia,
      mediaCostData: {
        startDate: null,
        endDate: null,
        campaign: null,
        site: null,
        source: null,
        cost: 0,
        costWithAc: 0,
        costAcPercentage: 0,
      },
      isOpen: true,
      setIsOpen: setIsOpen,
    }));
    setIsOpen(true);
  };

  const handleExcelUpload = () => {
    setOptions((options) => ({
      ...options,
      mode: ModalMode.UploadExcel,
      isOpen: true,
      setIsOpen: setIsExcelModalOpen,
    }));
    setIsExcelModalOpen(true);
  };

  const handleGetTemplate = async () => {
    await getExcelTemplate();
  };

  return (
    <div className="mediaCost">
      <PageTitle>CM360 Media Cost Table</PageTitle>
      <Section>
        <Container maxWidth="xl">
          <div className="flex flex-wrap gap-4">
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={handleCreateMedia}
            >
              NEW
            </Button>
            <Button
              variant="contained"
              color="success"
              startIcon={<FileUploadIcon />}
              onClick={handleExcelUpload}
            >
              UPLOAD EXCEL
            </Button>
            <Button
              variant="contained"
              color="info"
              component="label"
              onClick={handleGetTemplate}
              startIcon={<DownloadIcon />}
            >
              Excel Template
            </Button>

            <FilterBox />
          </div>
        </Container>
      </Section>
      <Section>
        <Container maxWidth="xl">
          <span className="text-gray-400">{filterSortingString}</span>
        </Container>
      </Section>

      <Section>
        <Container maxWidth="xl">
          {test.length > 0 ? (
            <>
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableHead className="p-0">
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell className="min-w-[140px]">
                        <SortIconStatus
                          type="startDate"
                          mode={sortIconStatus.startDate}
                          clickHandler={sortHandler}
                          tableType={UploadExcelType.Media}
                          showName="Start Date"
                        />
                      </TableCell>
                      <TableCell className="min-w-[140px]">
                        <SortIconStatus
                          type="endDate"
                          mode={sortIconStatus.endDate}
                          clickHandler={sortHandler}
                          tableType={UploadExcelType.Media}
                          showName="End Date"
                        />
                      </TableCell>
                      <TableCell className="min-w-[180px]">
                        <SortIconStatus
                          type="campaign"
                          mode={sortIconStatus.campaign}
                          clickHandler={sortHandler}
                          tableType={UploadExcelType.Media}
                          showName="Campaign Name"
                        />
                      </TableCell>
                      <TableCell className="min-w-[100px]">
                        <SortIconStatus
                          type="site"
                          mode={sortIconStatus.site}
                          clickHandler={sortHandler}
                          tableType={UploadExcelType.Media}
                          showName="Site"
                        />
                      </TableCell>
                      <TableCell className="min-w-[120px]">
                        <SortIconStatus
                          type="source"
                          mode={sortIconStatus.source}
                          clickHandler={sortHandler}
                          tableType={UploadExcelType.Media}
                          showName="Source"
                        />
                      </TableCell>
                      <TableCell align="right" className="min-w-[140px]">
                        Actual Ad Spend
                      </TableCell>
                      <TableCell align="right" className="min-w-[160px]">
                        AC Percentage
                      </TableCell>
                      <TableCell align="right" className="min-w-[160px]">
                        Media Plan Budget
                      </TableCell>
                      <TableCell className="min-w-[160px]">
                        Data Source
                      </TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {test.map((row: CostRecord, index: number) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>{row.id}</TableCell>
                        <TableCell>{row.startDate}</TableCell>
                        <TableCell>{row.endDate}</TableCell>
                        <TableCell>{row.campaign.name}</TableCell>
                        <TableCell>{row.site.name}</TableCell>
                        <TableCell>{row.source.name}</TableCell>
                        <TableCell align="right">
                          {d3.format(',.2f')(row.cost)}
                          {/* Actual Ad Spend */}
                        </TableCell>
                        <TableCell align="right">
                          {d3.format('.2%')(row.costAcPercentage)}
                        </TableCell>
                        <TableCell align="right">
                          {d3.format(',.2f')(row.costWithAc)}
                          {/* Media Plan Budget */}
                        </TableCell>
                        <TableCell>{row.excelFile.name}</TableCell>
                        <TableCell className="min-w-[115px]" align="right">
                          <IconButton
                            onClick={(e) =>
                              handleEditDeleteMedia(row, ModalMode.EditMedia)
                            }
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={(e) =>
                              handleEditDeleteMedia(row, ModalMode.DeleteMedia)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={totalRecords}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <Section>There is no record.</Section>
          )}
        </Container>
      </Section>

      {isOpen && <MediaModal {...options} />}
      {isExcelModalOpen && (
        <ExcelModal
          mode={ModalMode.UploadExcel}
          isOpen={isExcelModalOpen}
          setIsOpen={setIsExcelModalOpen}
          uploadExcelType={UploadExcelType.Media}
        />
      )}
    </div>
  );
};

export default MediaCost;
