import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Container from '@mui/material/Container';
import Add from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {
  ModalMode,
  Sort,
  AdsSetupModalConfig,
  UploadExcelType,
} from '../components/InterfaceType';
import AdsSetupModal from '../components/AdsSetupModal';
import ExcelModal from '../components/ExcelModal';
import { RootState } from '../store';
import {
  changeTotalRecordNum,
  updateMediaRecords,
  updateRowsPerPageOptions,
  updatePage,
  updateSortingIcon,
} from '../store/adsSetupSlice';
import {
  AdsSetup,
  processSetupData,
  updateReduxFilterSorting,
} from '../store/utilities';
import { getExcelTemplate, getAdsSetup } from '../apis/adsSetupApis';
import SortIconStatus from '../components/SortIconStatus';
import FilterBoxForGoogleAds from '../components/FilterBoxForGoogleAds';
import PageTitle from '../components/PageTitle';
import Section from '../components/Section';
import * as d3 from 'd3-format';
import DownloadIcon from '@mui/icons-material/Download';

const GoogleAdsSetup = () => {
  const setup = useSelector((state: RootState) => state.setup);
  const {
    records: adsSetup,
    filterSortingOption,
    totalRecords,
    filter: isFilterSet,
    filterTimes,
    sortIconStatus,
    rowsPerPage,
    page,
    filterOptionsString: filterSortingString,
  } = setup;

  const dispatch = useDispatch();

  const [sort, setSort] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isExcelModalOpen, setIsExcelModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const getRecords = async () => {
      let records = await getAdsSetup({
        startDate: filterSortingOption.startDate,
        endDate: filterSortingOption.endDate,
        filterTarget: filterSortingOption.filterTarget,
        filterValue: filterSortingOption.filterValue,
        sortingTarget: filterSortingOption.sortingTarget,
        sortingMethod: filterSortingOption.sortingMethod,
        limit: rowsPerPage,
        page: page + 1,
      });

      let processed = processSetupData(records.results);

      dispatch(changeTotalRecordNum(records.total));
      dispatch(updateMediaRecords(processed));
    };
    getRecords();
  }, [
    page,
    rowsPerPage,
    sortIconStatus,
    isFilterSet,
    filterTimes,
    totalRecords,
  ]);

  const [options, setOptions] = useState<AdsSetupModalConfig>({
    mode: ModalMode.CreateGAS,
    isOpen: false,
    setIsOpen: setIsOpen,
    adsSetupData: {
      id: null,
      campaignId: null,
      campaignName: null,
      campaignType: null,
      campaignStatus: null,
      product: null,
      startDate: null,
      endDate: null,
      budget: 0,
    },
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    dispatch(updatePage({ page: newPage }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(
      updateRowsPerPageOptions({
        rowsPerPage: parseInt(event.target.value, 10),
      }),
    );
  };

  const sortHandler = (type: string, mode: string) => {
    setSort(true);

    updateReduxFilterSorting(
      type,
      'sortingTarget',
      UploadExcelType.GoogleAdsSetup,
    );
    updateReduxFilterSorting(
      mode,
      'sortingMethod',
      UploadExcelType.GoogleAdsSetup,
    );
    if (!type) {
      dispatch(updateSortingIcon({ type: '', mode: Sort.DEFAULT }));
    }

    mode === 'DESC'
      ? dispatch(updateSortingIcon({ type: type, mode: Sort.DESC }))
      : dispatch(updateSortingIcon({ type: type, mode: Sort.ASC }));
  };

  const handleEditDeleteAdsSetup = (row: AdsSetup, modalMode: ModalMode) => {
    setOptions((options) => ({
      ...options,
      mode: modalMode,
      adsSetupData: {
        ...row,
        id: row.id,
        startDate: new Date(row.startDate),
        endDate: new Date(row.endDate),
        excelFileId: row.excelFile,
      },
      isOpen: true,
      setIsOpen: setIsOpen,
    }));
    setIsOpen(true);
  };

  const handleCreateAdsSetup = () => {
    setOptions((options) => ({
      ...options,
      mode: ModalMode.CreateGAS,
      adsSetupData: {
        campaignId: null,
        campaignName: null,
        campaignType: null,
        campaignStatus: null,
        product: null,
        startDate: null,
        endDate: null,
        budget: 0,
      },
      isOpen: true,
      setIsOpen: setIsOpen,
    }));
    setIsOpen(true);
  };

  const handleExcelUpload = () => {
    setOptions((options) => ({
      ...options,
      mode: ModalMode.UploadExcel,
      isOpen: true,
      setIsOpen: setIsExcelModalOpen,
    }));
    setIsExcelModalOpen(true);
  };

  const handleGetTemplate = async () => {
    await getExcelTemplate();
  };

  return (
    <div>
      <PageTitle>Google Ads Setup</PageTitle>
      <Section>
        <Container maxWidth="xl">
          <div className="flex flex-wrap gap-4">
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={() => handleCreateAdsSetup()}
            >
              NEW
            </Button>
            <Button
              variant="contained"
              color="success"
              startIcon={<FileUploadIcon />}
              onClick={() => handleExcelUpload()}
            >
              UPLOAD EXCEL
            </Button>
            <Button
              variant="contained"
              color="info"
              component="label"
              onClick={handleGetTemplate}
              startIcon={<DownloadIcon />}
            >
              Excel Template
            </Button>

            <FilterBoxForGoogleAds tableType={UploadExcelType.GoogleAdsSetup} />
          </div>
        </Container>
      </Section>
      <Section>
        <Container maxWidth="xl">
          <span className="text-gray-400">{filterSortingString}</span>
        </Container>
      </Section>

      <Section>
        <Container maxWidth="xl">
          {adsSetup.length > 0 ? (
            <>
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell className="min-w-[160px]">
                        <SortIconStatus
                          type="campaignId"
                          mode={sortIconStatus.campaignId}
                          clickHandler={sortHandler}
                          tableType={UploadExcelType.GoogleAdsSetup}
                          showName="Campaign ID"
                        />
                      </TableCell>
                      <TableCell className="min-w-[170px]">
                        <SortIconStatus
                          type="campaignName"
                          mode={sortIconStatus.campaignName}
                          clickHandler={sortHandler}
                          tableType={UploadExcelType.GoogleAdsSetup}
                          showName="Campaign Name"
                        />
                      </TableCell>

                      <TableCell className="min-w-[170px]">
                        <SortIconStatus
                          type="campaignType"
                          mode={sortIconStatus.campaignType}
                          clickHandler={sortHandler}
                          tableType={UploadExcelType.GoogleAdsSetup}
                          showName="Campaign Type"
                        />
                      </TableCell>

                      <TableCell className="min-w-[140px]">
                        <SortIconStatus
                          type="product"
                          mode={sortIconStatus.product}
                          clickHandler={sortHandler}
                          tableType={UploadExcelType.GoogleAdsSetup}
                          showName="Product"
                        />
                      </TableCell>
                      <TableCell className="min-w-[200px]">
                        <SortIconStatus
                          type="startDate"
                          mode={sortIconStatus.startDate}
                          clickHandler={sortHandler}
                          tableType={UploadExcelType.GoogleAdsSetup}
                          showName="Campaign Start Date"
                        />
                      </TableCell>
                      <TableCell className="min-w-[200px]">
                        <SortIconStatus
                          type="endDate"
                          mode={sortIconStatus.endDate}
                          clickHandler={sortHandler}
                          tableType={UploadExcelType.GoogleAdsSetup}
                          showName="Campaign End Date"
                        />
                      </TableCell>
                      <TableCell align="right" className="min-w-[120px]">
                        Budget
                      </TableCell>
                      <TableCell className="min-w-[180px]">
                        <SortIconStatus
                          type="campaignStatus"
                          mode={sortIconStatus.campaignStatus}
                          clickHandler={sortHandler}
                          tableType={UploadExcelType.GoogleAdsSetup}
                          showName="Campaign Status"
                        />
                      </TableCell>
                      <TableCell className="min-w-[200px]">
                        Data Source
                      </TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {adsSetup.map((row: AdsSetup, index: number) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>{row.id}</TableCell>
                        <TableCell>{row.campaignId}</TableCell>
                        <TableCell>{row.campaignName}</TableCell>
                        <TableCell>{row.campaignType}</TableCell>
                        <TableCell>{row.product}</TableCell>
                        <TableCell>{row.startDate}</TableCell>
                        <TableCell>{row.endDate}</TableCell>
                        <TableCell align="right">
                          {d3.format(',.2f')(row.budget)}
                        </TableCell>
                        <TableCell>{row.campaignStatus}</TableCell>
                        <TableCell>{row.excelFile.name}</TableCell>
                        <TableCell className="min-w-[115px]" align="right">
                          <IconButton
                            onClick={(e) =>
                              handleEditDeleteAdsSetup(row, ModalMode.EditGAS)
                            }
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={(e) =>
                              handleEditDeleteAdsSetup(row, ModalMode.DeleteGAS)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={totalRecords}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <Section>There is no record.</Section>
          )}
        </Container>
      </Section>

      {isOpen && <AdsSetupModal {...options} />}

      {isExcelModalOpen && (
        <ExcelModal
          mode={ModalMode.UploadExcel}
          isOpen={isExcelModalOpen}
          setIsOpen={setIsExcelModalOpen}
          uploadExcelType={UploadExcelType.GoogleAdsSetup}
        />
      )}
    </div>
  );
};

export default GoogleAdsSetup;
