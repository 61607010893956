import React from 'react';
import { Modal, Typography, Box, Stack } from '@mui/material';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 600,
  maxHeight: '95%',
  width: 'calc(100% - 40px)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '5px',
  padding: 3,
  overflowY: 'auto',
  '@media screen and (min-width: 600px)': {
    padding: 4,
  },
};

interface ModalProps {
  top: string;
  middle: ReactJSXElement;
  bottom: ReactJSXElement;
}

const CustomModal: React.FC<ModalProps> = ({ top, middle, bottom }) => {
  return (
    <Modal open={true}>
      <Box sx={style} component="form">
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ marginBottom: '20px', textAlign: 'center' }}
        >
          {top}
        </Typography>
        <Stack spacing={3}>
          {middle}
          <div>{bottom}</div>
        </Stack>
      </Box>
    </Modal>
  );
};

export default CustomModal;
