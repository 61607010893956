import React from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { logout } from '../store/userSlice';

interface IPage {
  path?: string;
  text?: string;
  isActive?: boolean;
}

const pages: IPage[] = [
  {
    path: '/channel-mapping',
    text: 'Channel Mapping',
  },
  {
    path: '/media-cost',
    text: 'CM360 Media Cost',
  },
  {
    path: '/google-ads-setup',
    text: 'Google Ads Setup',
  },
  {
    path: '/google-ads-performance',
    text: 'Google Ads Performance',
  },
];

export type MenuItemProps = {
  page: IPage;
};

const MenuItem = ({ page, ...props }: MenuItemProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigate = (pathname: string) => {
    navigate(pathname, { replace: true });
  };

  const handleSignOut = () => {
    dispatch(logout());
    navigate('/login', { replace: true });
  };

  const menuItemClass = twMerge(
    'w-full text-left lg:w-auto lg:px-1 justify-start text-gray-800 lg:border-b-2 lg:border-transparent lg:border-solid rounded-none',
    clsx(
      page.isActive &&
        'bg-fs-500 text-white lg:bg-white lg:text-gray-800 lg:border-fs-500',
    ),
  );
  return (
    <Button
      className={menuItemClass}
      {...props}
      onClick={() => (page.path ? handleNavigate(page.path) : handleSignOut())}
      disabled={page.isActive}
    >
      {page.text}
    </Button>
  );
};

const HeaderMenu = () => {
  const location = useLocation();
  const currentPage = location.pathname;

  return (
    <div className="menu flex flex-wrap items-center justify-end gap-4">
      {pages.map((page, idx) => {
        const active =
          currentPage === '/' && page.path === '/media-cost'
            ? true
            : page.path === currentPage;
        return (
          <MenuItem
            key={`header-menu-${idx}`}
            page={{ ...page, isActive: active }}
          />
        );
      })}
    </div>
  );
};

export default HeaderMenu;
