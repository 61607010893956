import Router from './router';
import './assets/css/index.css';
import { useEffect } from 'react';
import WithAxios from './pages/WithAxios';
import { getCSRFToken } from './apis/authApis';
import { AuthProvider } from './context';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { store } from './store';
import { Provider } from 'react-redux';

const theme = createTheme({
  spacing: [4, 8, 12, 16, 32, 64],
  palette: {
    primary: {
      main: '#64c7c7',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#969696',
      contrastText: '#ffffff',
    },
  },
});

const App = () => {
  useEffect(() => {
    getCSRFToken();
  }, []);
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <WithAxios>
            <Router />
          </WithAxios>
        </AuthProvider>
      </ThemeProvider>
    </Provider>
  );
};
export default App;
