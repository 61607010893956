import RefreshOutlined from '@mui/icons-material/RefreshOutlined';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Typography,
  TextField,
  Select,
  MenuItem,
  CardActions,
  Menu,
  createTheme,
  ThemeProvider,
  Popover,
} from '@mui/material';
import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateFilterSortingOptions,
  updateFilterSortingString,
  updateFilterState,
  updateFilterTimes,
} from '../store/mediaSlice';
import TuneIcon from '@mui/icons-material/Tune';
import { RootState } from '../store';
import { Sort, UploadExcelType } from './InterfaceType';
import { tableHeader, updateReduxFilterSorting } from '../store/utilities';
import { getAutoComplete } from '../apis/mediaCostApis';
import { Autocomplete } from '@mui/material';
import { theme } from './FilterBoxForGoogleAds';

interface Option {
  name: string;
  label: string;
  id: number;
}

let filterTheme = createTheme(theme, {
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          minWidth: 200,
          flex: 1,
        },
      },
    },
  },
});

const FilterBox: React.FC = () => {
  const dispatch = useDispatch();
  const filterSortingOption = useSelector(
    (state: RootState) => state.media.filterSortingOption,
  );
  const filter = useSelector((state: RootState) => state.media.filter);
  const [filterButton, setFilterButton] = useState<boolean>(false);
  const [options, setOptions] = useState<Option[]>([]);
  const [query, setQuery] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handleResetFilter = () => {
    // update filter sorting setting

    dispatch(updateFilterState({ state: false }));
    setFilterButton(false);
    dispatch(updateFilterSortingString({ filterOptionString: '' }));
    dispatch(
      updateFilterSortingOptions({
        startDate: '',
        endDate: '',
        filterTarget: '',
        filterValue: '',
        sortingMethod: Sort.ASC,
        sortingTarget: '',
      }),
    );
    setAnchorEl(null);
  };

  const handleFilterOptions = () => {
    if (
      filterSortingOption.startDate ||
      filterSortingOption.endDate ||
      (filterSortingOption.filterTarget && filterSortingOption.filterValue)
    ) {
      dispatch(updateFilterState({ state: true }));
      dispatch(updateFilterTimes());

      let checkStartEndDate = `Date: From ${
        filterSortingOption.startDate ? filterSortingOption.startDate : ' - '
      } to ${
        filterSortingOption.endDate ? filterSortingOption.endDate : ' - '
      }`;
      let filterOptionsString = `${
        filterSortingOption.startDate || filterSortingOption.endDate
          ? checkStartEndDate
          : ''
      }${
        (filterSortingOption.startDate || filterSortingOption.endDate) &&
        filterSortingOption.filterTarget &&
        filterSortingOption.filterValue
          ? ', '
          : ''
      }${
        filterSortingOption.filterTarget && filterSortingOption.filterValue
          ? filterSortingOption.filterTarget +
            ': ' +
            filterSortingOption.filterValue
          : ''
      }`;
      dispatch(
        updateFilterSortingString({ filterOptionString: filterOptionsString }),
      );
      setFilterButton(false);
      setQuery('');
      setAnchorEl(null);
    }
  };

  const observer = useRef<null | IntersectionObserver>();

  const lastOptionRef = useCallback((node: any) => {
    if (isLoading) return;
    if (observer.current) {
      observer.current.disconnect();
    }
    observer.current = new IntersectionObserver(async (entries) => {
      if (entries[0].isIntersecting) {
        setCurrentPage((currentPage) => currentPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  useEffect(() => {
    if (
      filterSortingOption.filterTarget === 'Campaign Name' ||
      filterSortingOption.filterTarget === 'Site' ||
      filterSortingOption.filterTarget === 'Source' ||
      filterSortingOption.filterTarget === ''
    ) {
      getAuto();
    }
  }, [filterSortingOption.filterTarget, query, currentPage]);

  const getAuto = async () => {
    try {
      setIsLoading(true);
      if (filterSortingOption.filterTarget) {
        const { results } = await getAutoComplete(
          {
            keyword: query,
            page: currentPage,
          },
          filterSortingOption.filterTarget,
        );
        if (results.length) {
          if (currentPage === 1) {
            setOptions(
              results.map((d: Option) => ({
                label: d.name,
                name: d.name,
              })),
            );
          } else {
            setOptions((options) => [
              ...options,
              ...results.map((d: Option) => ({
                label: d.name,
                id: d.id,
                name: d.name,
              })),
            ]);
            setIsLoading(false);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickFilterBox = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterButton(!filterButton);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseFilter = () => {
    setAnchorEl(null);
  };
  return (
    <div className="flex flex-row min-w-[230px] gap-2">
      <div>
        <Button
          id="basic-button"
          variant="contained"
          startIcon={<TuneIcon />}
          color={filter === true ? 'primary' : 'secondary'}
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickFilterBox}
        >
          FILTER
        </Button>
        <ThemeProvider theme={filterTheme}>
          <Popover
            anchorEl={anchorEl}
            anchorReference="anchorEl"
            open={open}
            onClose={handleCloseFilter}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Card>
              <CardContent>
                <Typography>Search</Typography>
                <FormControl size="small">
                  <Select
                    size="small"
                    displayEmpty
                    value={filterSortingOption.filterTarget}
                    onChange={(e) => {
                      updateReduxFilterSorting(
                        e.target.value as string,
                        'filterTarget',
                        UploadExcelType.Media,
                      );
                    }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return 'Select Column Name';
                      }
                      return selected;
                    }}
                  >
                    {tableHeader.slice(2, 5).map((item) => (
                      <MenuItem value={item.header} key={`select-${item.type}`}>
                        {item.header}
                      </MenuItem>
                    ))}
                  </Select>

                  <Autocomplete
                    size="small"
                    id="cm-site"
                    options={options}
                    isOptionEqualToValue={(option, value) =>
                      option && value && option.name === value.name
                    }
                    value={filterSortingOption.filterValue}
                    freeSolo
                    onChange={(e, option, reason) => {
                      if (
                        reason === 'selectOption' &&
                        typeof option !== 'string'
                      ) {
                        updateReduxFilterSorting(
                          option?.name as string,
                          'filterValue',
                          UploadExcelType.Media,
                        );
                      }
                    }}
                    renderOption={(props, option) => {
                      if (options[options.length - 1].id === option.id) {
                        return (
                          <li
                            {...props}
                            data-name={option.label}
                            ref={lastOptionRef}
                          >
                            {option.label}
                          </li>
                        );
                      }
                      return (
                        <li {...props} data-name={option.label}>
                          {option.label}
                        </li>
                      );
                    }}
                    onInputChange={(e, value) => {
                      setQuery(value);
                      setCurrentPage(1);
                      updateReduxFilterSorting(
                        value,
                        'filterValue',
                        UploadExcelType.Media,
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Keyword" />
                    )}
                  />
                </FormControl>
              </CardContent>

              <CardActions>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setFilterButton(false);
                    setAnchorEl(null);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleFilterOptions}
                >
                  FILTER
                </Button>
              </CardActions>
            </Card>
          </Popover>
        </ThemeProvider>
      </div>

      {filter && (
        <>
          <Button
            variant="contained"
            color="inherit"
            startIcon={<RefreshOutlined />}
            onClick={handleResetFilter}
            className="mx-[10px]"
          >
            RESET
          </Button>
        </>
      )}
    </div>
  );
};

export default FilterBox;
