import axios, { AxiosResponse } from 'axios';

interface User {
  username: string;
  password: string;
}

interface ResetPassword {
  username?: string;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface ReturnData {
  access_token: string;
}

type CSRFToken = string;

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
});

export async function signin(user: User): Promise<ReturnData | undefined> {
  try {
    const res: AxiosResponse = await Axios.post('/v1/auth/login', {
      username: user.username,
      password: user.password,
    });

    return res?.data;
  } catch (err) {
    console.log(err);
  }
}

export async function resetPassword(
  data: ResetPassword,
): Promise<ReturnData | undefined> {
  try {
    const res: AxiosResponse = await Axios.patch(`v1/user/me`, {
      username: data.username,
      password: data.newPassword,
      oldPassword: data.oldPassword,
      passwordConfirmation: data.confirmPassword,
    });

    return res?.data;
  } catch (err) {
    throw err;
  }
}

export async function getCSRFToken(): Promise<CSRFToken> {
  try {
    const res: AxiosResponse = await Axios.get('v1/csrf');
    // set header for csrf token
    Axios.defaults.headers.common['X-CSRF-TOKEN'] = res.data;

    return res.data;
  } catch (err: unknown) {
    console.error(err);
    return '';
  }
}
