import React, { useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

export type MobileMenuProps = React.HTMLAttributes<HTMLDivElement> & {
  headerHeight?: number;
  open?: boolean;
};

const MobileMenu = React.forwardRef<HTMLDivElement, MobileMenuProps>(
  ({ children, headerHeight = 60, open = false, className, ...props }, ref) => {
    const [isOpen, setIsOpen] = useState(open);

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const contentHeight = useMemo(() => {
      return windowHeight - headerHeight;
    }, [windowHeight, headerHeight]);

    const resizeHandler = () => {
      setWindowHeight(() => window.innerHeight);
      setIsOpen(false);
    };

    useEffect(() => {
      window.addEventListener('resize', resizeHandler);
      return () => window.removeEventListener('resize', resizeHandler);
    }, []);

    const toggleHandler = () => {
      setIsOpen((state) => !state);
    };

    const classes = twMerge('mobileMenu', className);

    return (
      <div {...props} ref={ref} className={classes}>
        <button
          className={clsx('mobileMenu-toggle', isOpen && 'open')}
          onClick={toggleHandler}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>

        <div
          className={clsx('mobileMenu-bg z-50', {
            ['translate-x-0']: isOpen,
          })}
          style={{ top: headerHeight, height: contentHeight }}
          onClick={toggleHandler}
        >
          <div className="mobileMenu-content">{children}</div>
        </div>
      </div>
    );
  },
);

export default MobileMenu;
