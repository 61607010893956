import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { resetPassword } from '../apis/authApis';
import { TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUser } from '../store/userSlice';
import Section from '../components/Section';

function ResetPassword(): JSX.Element {
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>();
  const [successMsg, setSuccessMsg] = useState<string>();

  const user = useSelector(selectUser);

  const handleSubmit = async (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault();
    if (confirmPassword !== newPassword) {
      setErrorMsg('Confirm Password does not match');
      return;
    }
    try {
      await resetPassword({
        username: user && user.username,
        newPassword: newPassword,
        oldPassword: oldPassword,
        confirmPassword: confirmPassword,
      });
      setErrorMsg('');
      setSuccessMsg('Password is updated successfully');
    } catch (err: any) {
      setErrorMsg(err.response?.data.message);
    }
  };

  return (
    <div className="login">
      <Section className="w-full h-screen flex items-center justify-center">
        <div className="modal w-full max-w-[580px] bg-white shadow-xl p-8 flex flex-col gap-8">
          <div className="modal-title">Change Password</div>
          <form
            onSubmit={(e) => handleSubmit(e)}
            className="flex flex-col gap-8"
          >
            <div className="flex">
              <TextField
                id="old-password"
                label="Old Password"
                variant="outlined"
                sx={{ width: '100%' }}
                required
                type={'password'}
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </div>
            <div className="flex">
              <TextField
                id="new-password"
                label="New password"
                type={'password'}
                required
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                variant="outlined"
                sx={{ width: '100%' }}
              />
            </div>
            <div className="flex">
              <TextField
                id="confirm-password"
                label="Confirm Password"
                required
                type={'password'}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                variant="outlined"
                sx={{ width: '100%' }}
              />
            </div>
            {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
            {successMsg && <Alert severity="success">{successMsg}</Alert>}

            <Button color="primary" variant="contained" type="submit">
              CONFIRM
            </Button>
          </form>
        </div>
      </Section>
    </div>
  );
}

export default ResetPassword;
