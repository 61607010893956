import React from 'react';
import { twMerge } from 'tailwind-merge';

type Props = { children: React.ReactNode; className?: string };

const Section = ({ children, className }: Props) => {
  const classes = twMerge('my-8', className);

  return <section className={classes}>{children}</section>;
};

export default Section;
