import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { Autocomplete, TextField, Button, Alert } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  createMedia,
  updateMedia,
  deleteMedia,
  getAutoCompleteForModal,
} from '../apis/mediaCostApis';
import { MediaCostData, MediaModalConfig, ModalMode } from './InterfaceType';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import {
  createMediaRecords,
  deleteMediaRecordsById,
  updateMediaRecordsById,
} from '../store/mediaSlice';
import CustomModal from './CustomModal';
import PercentIcon from '@mui/icons-material/Percent';
import Big from 'big.js';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
interface Option {
  name: string;
  label: string;
  id: number;
}

const calculateCostWithAc = (cost: string, ACPercentage: string): number => {
  if (
    !isNaN(Number(ACPercentage)) &&
    !isNaN(Number(cost)) &&
    Number(ACPercentage) < 100 &&
    Number(cost) > 0
  )
    if (Number(ACPercentage))
      return Number(
        new Big(cost)
          .div(new Big(1).minus(new Big(ACPercentage).div(100)))
          .round(2),
      );
    else
      return Number(new Big(cost).div(new Big(1).minus(new Big(0))).round(2));
  if (isNaN(Number(cost)) || Number(cost) < 0 || Number(ACPercentage)>=100) return 0;
  return Number(cost);
};

export const calculateCostAcPercent = (
  cost: string,
  costWithAc: string,
): number => {
  if (
    !isNaN(Number(costWithAc)) &&
    !isNaN(Number(cost)) &&
    Number(costWithAc) > 0 &&
    Number(cost) > 0
  )
    return Number(
      new Big(1).minus(new Big(cost).div(new Big(costWithAc))).times(100),
    );
  else if (isNaN(Number(cost)) || Number(cost) < 0) return 0;
  return Number(cost);
};

const MediaModal: React.FC<MediaModalConfig> = ({
  mode,
  setIsOpen,
  mediaCostData,
}) => {
  const [newMediaData, setNewMediaData] =
    useState<MediaCostData>(mediaCostData);
  const [isSubmittedOnce, setIsSubmittedOnce] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const dispatch = useDispatch();
  const [options, setOptions] = useState<Option[]>([]);
  const [query, setQuery] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(true);
  const [currentAutoTarget, setCurrentAutoTarget] =
    useState<string>('cm360-sites');

  const isFieldNonEmpty = (obj: MediaCostData) => {
    if (mode === 'CREATE_MEDIA' || mode === 'EDIT_MEDIA')
      return Object.entries(obj).every(([key, value]) => {
        if (typeof value === 'number') {
          return (
            !isNaN(Number(obj.costAcPercentage)) &&
            obj.costAcPercentage !== null &&
            obj.costAcPercentage <= 100 &&
            obj.cost !== null &&
            obj.cost >= 0
          );
        } else if (typeof value === 'object' || typeof value === 'string') {
          return value !== null && value !== undefined && value !== '';
        }
        return true;
      });
    else {
      return true;
    }
  };

  const buttonText = useMemo(() => {
    if (mode === 'CREATE_MEDIA') return 'Create';
    else if (mode === 'EDIT_MEDIA') return 'Save';
    else if (mode === 'DELETE_MEDIA') return 'Confirm';
    return '';
  }, [mode]);

  const titleText = useMemo(() => {
    if (mode === 'CREATE_MEDIA') return 'New Record';
    else if (mode === 'EDIT_MEDIA') return 'Edit Record';
    else if (mode === 'DELETE_MEDIA') return 'Delete Record';
    return '';
  }, [mode]);

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): Promise<void> => {
    e.preventDefault();
    setIsSubmittedOnce(true);
    setAlertMessage('');

    if (
      !isFieldNonEmpty(newMediaData) ||
      isNaN(Number(newMediaData.startDate)) ||
      isNaN(Number(newMediaData.endDate))
    ) {
      setAlertMessage('Please fill all required fields');
      return;
    }

    try {
      setIsLoading(true);
      switch (mode) {
        case ModalMode.CreateMedia:
          let createdRecord = await createMedia(newMediaData);

          dispatch(createMediaRecords({ ...createdRecord }));
          break;
        case ModalMode.EditMedia:
          let updatedRecord = await updateMedia(newMediaData);
          dispatch(updateMediaRecordsById({ ...updatedRecord }));
          break;
        case ModalMode.DeleteMedia:
          let deletedRecord = await deleteMedia(newMediaData);
          if (deletedRecord === 200) {
            dispatch(deleteMediaRecordsById({ id: newMediaData.id }));
            setIsOpen(false);
          }
          break;
        default:
          break;
      }
      setIsLoading(false);
      setIsOpen(false);
    } catch (err) {
      setIsLoading(false);
      handleSetAlertMessage(err);
    }
  };

  const handleSetAlertMessage = (err: any) => {
    if (Array.isArray(err.response.data.message))
      setAlertMessage(err.response.data.message[0]);
    else {
      setAlertMessage(err.response.data.message);
    }
  };

  const isDateRangeValid = (startDate: any, endDate: any) => {
    if (startDate && endDate) {
      const date1 = new Date(startDate);
      const date2 = new Date(endDate);
      return date1 <= date2;
    }
    return false;
  };

  const observer = useRef<null | IntersectionObserver>();

  const lastOptionRef = useCallback((node: any) => {
    if (isLoadingOptions) return;
    if (observer.current) {
      observer.current.disconnect();
    }
    observer.current = new IntersectionObserver(async (entries) => {
      if (entries[0].isIntersecting) {
        setCurrentPage((currentPage) => currentPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  useEffect(() => {
    if (mode === 'CREATE_MEDIA' || mode === 'EDIT_MEDIA') getAuto();
  }, [currentAutoTarget, query, currentPage]);

  const getAuto = async () => {
    try {
      setIsLoadingOptions(true);
      const { results } = await getAutoCompleteForModal(
        {
          keyword: query,
          page: currentPage,
        },
        currentAutoTarget,
      );
      if (results.length) {
        if (currentPage === 1) {
          setOptions(
            results.map((d: Option, i: number) => ({
              label: d.name,
              name: d.name,
              id: d.id || i,
            })),
          );
          setIsLoadingOptions(false);
        } else {
          setOptions((options) => [
            ...options,
            ...results.map((d: Option, i: number) => ({
              label: d.name,
              name: d.name,
              id: d.id || i,
            })),
          ]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const hashCode = (s: string) => {
    for (var i = 0, h = 0; i < s.length; i++)
      h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
    return h;
  };

  const renderOptions = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: Option,
  ) => {
    if (options[options.length - 1].id === option.id) {
      return (
        <li {...props} data-name={option.label} ref={lastOptionRef}>
          {option.label}
        </li>
      );
    }
    return (
      <li {...props} data-name={option.label}>
        {option.label}
      </li>
    );
  };

  const handleSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setNewMediaData((data) => ({
        ...data,
      }));
    } else {
      setNewMediaData((data) => ({
        ...data,
      }));
    }
    setChecked(e.target.checked);
  };

  return (
    <CustomModal
      top={titleText}
      middle={
        <>
          {mode === 'EDIT_MEDIA' || mode === 'CREATE_MEDIA' ? (
            <>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start Date"
                  value={newMediaData!.startDate}
                  inputFormat="yyyy-MM-dd"
                  onChange={(newValue) => {
                    setNewMediaData((data) => ({
                      ...data,
                      startDate: newValue,
                    }));
                    if (
                      alertMessage === 'startDate must be on or before endDate'
                    )
                      setAlertMessage('');
                  }}
                  maxDate={
                    newMediaData.endDate ? newMediaData.endDate : undefined
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      helperText={
                        (newMediaData.startDate === null ||
                          isNaN(Number(newMediaData.startDate))) &&
                        isSubmittedOnce
                          ? 'Invalid date'
                          : null
                      }
                      error={
                        (newMediaData.startDate === null ||
                          !isDateRangeValid(
                            newMediaData.startDate,
                            newMediaData.endDate,
                          ) ||
                          isNaN(Number(newMediaData.startDate))) &&
                        isSubmittedOnce
                      }
                    />
                  )}
                />
                <DatePicker
                  label="End Date"
                  inputFormat="yyyy-MM-dd"
                  value={newMediaData!.endDate}
                  onChange={(newValue) => {
                    setNewMediaData((data) => ({ ...data, endDate: newValue }));
                    if (
                      alertMessage === 'startDate must be on or before endDate'
                    )
                      setAlertMessage('');
                  }}
                  minDate={
                    newMediaData.startDate ? newMediaData.startDate : undefined
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      helperText={
                        (newMediaData.endDate === null ||
                          isNaN(Number(newMediaData.endDate))) &&
                        isSubmittedOnce
                          ? 'Invalid date'
                          : null
                      }
                      error={
                        (newMediaData.endDate === null ||
                          !isDateRangeValid(
                            newMediaData.startDate,
                            newMediaData.endDate,
                          ) ||
                          isNaN(Number(newMediaData.endDate))) &&
                        isSubmittedOnce
                      }
                    />
                  )}
                />
              </LocalizationProvider>
              <Autocomplete
                id="campaign-name"
                options={options}
                isOptionEqualToValue={(option, value) =>
                  option && value && option.name === value.name
                }
                defaultValue={
                  mediaCostData.campaign
                    ? {
                        name: mediaCostData.campaign.name,
                        label: mediaCostData.campaign.name,
                        id: mediaCostData.campaign.id,
                      }
                    : undefined
                }
                onChange={(e, option, reason) => {
                  if (reason === 'selectOption') {
                    setOptions([]);
                  }
                  setNewMediaData((data) => ({
                    ...data,
                    campaign: option && { id: option.id, name: option.name },
                  }));
                }}
                renderOption={(props, option) => renderOptions(props, option)}
                onInputChange={(e, value) => {
                  setQuery(value);
                  setCurrentPage(1);
                }}
                onFocus={(e) => {
                  setCurrentAutoTarget('cm360-campaigns');
                  setQuery('');
                  setCurrentPage(1);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Campaign Name"
                    error={newMediaData.campaign === null && isSubmittedOnce}
                    required
                    value={newMediaData?.campaign || ''}
                  />
                )}
              />
              <Autocomplete
                id="cm-site"
                options={options}
                isOptionEqualToValue={(option, value) =>
                  option && value && option.name === value.name
                }
                defaultValue={
                  mediaCostData.site
                    ? {
                        name: mediaCostData.site.name,
                        label: mediaCostData.site.name,
                        id: mediaCostData.site.id,
                      }
                    : undefined
                }
                onChange={(e, option, reason) => {
                  if (reason === 'selectOption') {
                    setOptions([]);
                  }
                  setCurrentAutoTarget('cm360-sites');
                  setAlertMessage('');
                  setNewMediaData((data) => ({
                    ...data,
                    site: option && { name: option.label, id: option.id },
                  }));
                }}
                renderOption={(props, option) => renderOptions(props, option)}
                onInputChange={(e, value) => {
                  setQuery(value);
                  setCurrentPage(1);
                }}
                onFocus={(e) => {
                  setCurrentAutoTarget('cm360-sites');
                  setQuery('');
                  setCurrentPage(1);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="CM site"
                    required
                    error={newMediaData.site === null && isSubmittedOnce}
                  />
                )}
              />
              <Autocomplete
                id="source"
                options={options}
                isOptionEqualToValue={(option, value) =>
                  option && value && option.name === value.name
                }
                onChange={(e, option, reason) => {
                  if (reason === 'selectOption') {
                    setOptions([]);
                  }
                  setNewMediaData((data) => ({
                    ...data,
                    source: option && { name: option.name },
                  }));
                }}
                onInputChange={(e, value) => {
                  setQuery(value);
                  setCurrentPage(1);
                }}
                onFocus={(e) => {
                  setCurrentAutoTarget('sources');
                  setQuery('');
                  setCurrentPage(1);
                }}
                renderOption={(props, option) => renderOptions(props, option)}
                defaultValue={
                  mediaCostData.source
                    ? {
                        name: mediaCostData.source.name,
                        label: mediaCostData.source.name,
                        id: hashCode(mediaCostData.source.name),
                      }
                    : undefined
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Source"
                    required
                    error={newMediaData.source === null && isSubmittedOnce}
                  />
                )}
              />
              <TextField
                required
                type="number"
                id="cost"
                label="Actual Ad Spend (HKD)"
                value={
                  Number(newMediaData?.cost) >= 0
                    ? Number(newMediaData?.cost)
                    : ''
                }
                onChange={(e) => {
                  setNewMediaData((data) => ({
                    ...data,
                    cost: parseFloat(e.target.value),
                    costWithAc: calculateCostWithAc(
                      e.target.value,
                      data.costAcPercentage.toString(),
                    ),
                  }));
                }}
                helperText={isNaN(newMediaData.cost) && 'Must be at least 0'}
                error={isNaN(newMediaData.cost)}
              />
              <FormGroup>
                <FormControlLabel
                  control={<Switch checked={checked} onChange={handleSwitch} />}
                  label="Auto Calculate media plan budget"
                />
              </FormGroup>
              <TextField
                required
                disabled={!checked}
                type="number"
                id="costAcPercentage"
                label="AC Percentage"
                value={
                  Number(newMediaData?.costAcPercentage) >= 0
                    ? new Big(newMediaData?.costAcPercentage).round(2)
                    : ''
                }
                onChange={(e) => {
                  setNewMediaData((data) => ({
                    ...data,
                    costAcPercentage: parseFloat(e.target.value),
                    costWithAc: calculateCostWithAc(
                      data.cost.toString(),
                      e.target.value,
                    ),
                  }));
                }}
                InputProps={{
                  endAdornment: <PercentIcon style={{ opacity: '0.54' }} />,
                }}
                helperText={
                  (isNaN(newMediaData.costAcPercentage) ||
                    newMediaData.costAcPercentage >= 100 ||
                    newMediaData.costAcPercentage < 0) &&
                  'Must be at least 0 and smaller than 100'
                }
                error={
                  isNaN(newMediaData.costAcPercentage) ||
                  newMediaData.costAcPercentage >= 100 ||
                  newMediaData.costAcPercentage < 0
                }
              />
              <TextField
                required
                disabled={checked}
                type="number"
                id="costWithAc"
                label="Media Plan Budget (HKD)"
                value={
                  Number(newMediaData?.costWithAc) >= 0
                    ? Number(newMediaData?.costWithAc)
                    : ''
                }
                onChange={(e) => {
                  setNewMediaData((data) => ({
                    ...data,
                    costWithAc: parseFloat(e.target.value),
                    costAcPercentage: calculateCostAcPercent(
                      data.cost.toString(),
                      e.target.value,
                    ),
                  }));
                }}
              />

              {/* {!checked ? (
                <TextField
                  required
                  disabled={checked}
                  type="number"
                  id="costWithAc"
                  label="Media Plan Budget (HKD)"
                  value={
                    Number(newMediaData?.costWithAc) >= 0
                      ? Number(newMediaData?.costWithAc)
                      : ''
                  }
                  onChange={(e) => {
                    setNewMediaData((data) => ({
                      ...data,
                      costWithAc: parseFloat(e.target.value),
                    }));
                  }}
                />
              ) : (
                <>
                  <TextField
                    required
                    type="number"
                    id="costAcPercentage"
                    label="AC Percentage"
                    value={
                      Number(newMediaData?.costAcPercentage) >= 0
                        ? Number(newMediaData?.costAcPercentage)
                        : ''
                    }
                    onChange={(e) => {
                      setNewMediaData((data) => ({
                        ...data,
                        costAcPercentage: parseFloat(e.target.value),
                        costWithAc: 0,
                        // calculateCostWithAc(
                        //   data.cost.toString(),
                        //   e.target.value,
                        // ),
                      }));
                    }}
                    InputProps={{
                      endAdornment: <PercentIcon style={{ opacity: '0.54' }} />,
                    }}
                    helperText={
                      (isNaN(newMediaData.costAcPercentage) ||
                        newMediaData.costAcPercentage > 100 ||
                        newMediaData.costAcPercentage < 0) &&
                      'Must be non-negative and less than 100'
                    }
                    error={
                      isNaN(newMediaData.costAcPercentage) ||
                      newMediaData.costAcPercentage > 100 ||
                      newMediaData.costAcPercentage < 0
                    }
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      opacity: 0.5,
                    }}
                  >
                    <Typography>Media Plan Budget</Typography>
                    <Typography>
                      {d3.format(',.2f')(calCostWithAc)} HKD
                    </Typography>
                  </Box>
                </>
              )} */}
            </>
          ) : (
            ''
          )}
          {mode === 'DELETE_MEDIA' && (
            <div>Remove Media Record #{newMediaData.id}</div>
          )}
          {alertMessage !== '' && (
            <Alert severity="error">{alertMessage}</Alert>
          )}
        </>
      }
      bottom={
        <>
          <Button
            variant="contained"
            sx={{ marginRight: '10px' }}
            type="submit"
            onClick={(e) => handleSubmit(e)}
            disabled={isLoading}
          >
            {buttonText}
            {isLoading && (
              <CircularProgress
                style={{
                  color: 'white',
                  width: '15px',
                  height: '15px',
                  marginLeft: '5px',
                }}
              />
            )}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
        </>
      }
    ></CustomModal>
  );
};

export default MediaModal;
