import { GASfilterOptions } from '../components/FilterBoxForGoogleAds';
import { GoogleAdsSetupData } from '../components/InterfaceType';
import { excelFileType } from '../store/mediaSlice';
import { Axios } from './authApis';

export interface FilterSortingLimitPage {
  startDate: string;
  endDate: string;
  filterTarget: string;
  filterValue: string;
  sortingTarget: string;
  sortingMethod: string;
  limit: number;
  page: number;
}

export interface ReturnedAdsSetup {
  id: number;
  campaignId: string;
  campaignType: string;
  campaignStatus: string;
  product: string;
  startDate: Date;
  endDate: Date;
  budget: number;
  excelFile: excelFileType;
}

const PATH = 'v1/ads-campaign-setups';

export const createAdsSetup = async (
  data: GoogleAdsSetupData,
): Promise<ReturnedAdsSetup | undefined> => {
  try {
    let request = {
      ...data,
      startDate: data.startDate?.toISOString(),
      endDate: data.endDate?.toISOString(),
      budget: Number(data.budget),
      product: data.product,
    };

    const res = await Axios.post(PATH, request);

    return res.data;
  } catch (err) {
    //throw to modal to handle
    throw err;
  }
};
export const getAdsSetup = async (data: FilterSortingLimitPage) => {
  try {
    const filter =
      data.filterTarget && data.filterValue
        ? '&filter[field]=' +
          GASfilterOptions.filter(
            (item) => item.header === (data.filterTarget as string),
          )[0].type +
          '&filter[keyword]=' +
          data.filterValue
        : '';
    const sort =
      data.sortingMethod && data.sortingTarget && data.sortingMethod !== 'reset'
        ? '&sort[direction]=' +
          data.sortingMethod +
          '&sort[field]=' +
          data.sortingTarget
        : '';
    const url = `${PATH}?limit=${data.limit}&page=${data.page}${filter}${sort}`;

    const res = await Axios.get(url);

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const updateAdsSetup = async (data: GoogleAdsSetupData) => {
  try {
    const url = `${PATH}/${data.id}`;

    let request = {
      ...data,
      budget: Number(data.budget),
      startDate: data.startDate?.toISOString(),
      endDate: data.endDate?.toISOString(),
      // excelFile: null,
    };

    const res = await Axios.patch(url, request);

    return res.data;
  } catch (err) {
    throw err;
  }
};

export const deleteAdsSetup = async (data: GoogleAdsSetupData) => {
  try {
    const res = await Axios.delete(`${PATH}/${data.id}`);

    return res.status;
  } catch (err) {
    throw err;
  }
};

export const excelUploadForAdsSetup = async (
  formData: FormData,
): Promise<any> => {
  try {
    const url = `${PATH}/upload-excel-file`;

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    const res = await Axios.post(url, formData, config);

    return res.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getExcelTemplate = async () => {
  try {
    const res = await Axios.get(
      `${PATH}/download-excel-template`, //todo
      { responseType: 'blob' },
    );
    const [, filename] = res.headers['content-disposition'].split('filename=');
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', filename.replace(/['"]/g, ''));
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    console.log(err);
  }
};
