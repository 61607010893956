import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Source } from '../apis/mediaCostApis';
import { Sort } from '../components/InterfaceType';
import { Campaign, Site } from './channelMappingSlice';
import { excelFileType } from './mediaSlice';
import { AdsSetup } from './utilities';

export interface MediaSerializable {
  id: number;
  startDate: string;
  endDate: string;
  cost: number;
  costWithAc: number;
  campaign: Campaign;
  site: Site;
  source: Source;
  excelFile: excelFileType;
}
export interface FilterSortingOption {
  startDate: string;
  endDate: string;
  filterTarget: string;
  filterValue: string;
  sortingTarget: string;
  sortingMethod: string;
}
export interface MediaState {
  records: AdsSetup[];
  totalRecords: number;
  filter: boolean;
  filterTimes: number;
  filterSortingOption: FilterSortingOption;
  filterOptionsString: string;
  rowsPerPage: number;
  page: number;
  sortIconStatus: {
    startDate: Sort;
    endDate: Sort;
    campaignId: Sort;
    campaignType: Sort;
    campaignName: Sort;
    campaignStatus:Sort;
    product: Sort;
  };
}

const initialState: MediaState = {
  records: [],
  totalRecords: 0,
  filter: false,
  filterTimes: 0,
  filterSortingOption: {
    startDate: '',
    endDate: '',
    filterTarget: '',
    filterValue: '',
    sortingTarget: '',
    sortingMethod: '',
  },
  filterOptionsString: '',
  rowsPerPage: 25,
  page: 0,
  sortIconStatus: {
    startDate: Sort.DEFAULT,
    endDate: Sort.DEFAULT,
    campaignId: Sort.DEFAULT,
    campaignName: Sort.DEFAULT,
    campaignType: Sort.DEFAULT,
    campaignStatus: Sort.DEFAULT,
    product: Sort.DEFAULT,
  },
};

export const adsSetupSlice = createSlice({
  name: 'setup',
  initialState,
  reducers: {
    changeTotalRecordNum: (state, action) => {
      state.totalRecords = action.payload;
    },
    updateGASFilterSortingOptions: (state, action) => {
      state.filterSortingOption = _.merge(
        state.filterSortingOption,
        action.payload,
      );
    },
    updateRowsPerPageOptions: (state, action) => {
      state.rowsPerPage = action.payload.rowsPerPage;
      state.page = 0;
    },

    updatePage: (state, action) => {
      state.page = action.payload.page;
    },

    updateFilterTimes: (state) => {
      state.filterTimes++;
    },

    updateGASFilterState: (state, action) => {
      state.filter = action.payload.state;
    },

    updateGASFilterSortingString: (state, action) => {
      state.filterOptionsString = action.payload.filterOptionString;
    },

    updateSortingIcon: (state, action) => {
      let temp: keyof typeof state.sortIconStatus;
      for (temp in state.sortIconStatus) {
        state.sortIconStatus[temp] = Sort.DEFAULT;
      }
      state.sortIconStatus[
        action.payload.type as keyof typeof state.sortIconStatus
      ] = action.payload.mode;
    },

    // records
    deleteMediaRecords: (state) => {
      // delete all
      state.records = [];
    },
    updateMediaRecords: (state, action) => {
      // // just simple fetch data from database to update the media array
      state.records = [];
      state.records = [...action.payload];
    },
    createMediaRecords: (state, action) => {
      if (state.records.length < state.rowsPerPage) {
        const newRecord = {
          ...action.payload,
          excelFile: {
            dataType: '',
            id: NaN,
            name: 'Manual Input',
            storagePath: '',
          },
        };
        state.records.push(newRecord);
      }

      state.totalRecords = state.totalRecords + 1;
    },
    updateMediaRecordsById: (state, action) => {
      //
      const recordIndex = state.records.findIndex(
        (item) => item.id === action.payload.id,
      );

      const temp = [...state.records];
      if (recordIndex >= 0) {
        // merge the updated record to original
        temp[recordIndex] = _.merge(temp[recordIndex], action.payload);
        state.records = [];
        state.records = [...temp];
      }
    },
    deleteMediaRecordsById: (state, action) => {
      // Added
      const temp = state.records.filter(
        (item) =>
          // item.id === action.payload.channelId && item.sites.
          item.id !== action.payload.id,
      );

      state.records = [];
      state.records = [...temp];
      if (state.records.length === 0 && state.page > 0) {
        state.page--;
      }
      state.totalRecords--;
    },
    createAdsSetupByExcel: (state) => {
      // fetch again after upload
      state.totalRecords = state.totalRecords + 1;
    },
  },
});

export const {
  updateGASFilterSortingOptions,
  updatePage,
  updateRowsPerPageOptions,
  updateFilterTimes,
  updateGASFilterSortingString,
  updateGASFilterState,
  updateSortingIcon,
  changeTotalRecordNum,
  createMediaRecords,
  deleteMediaRecords,
  updateMediaRecords,
  updateMediaRecordsById,
  deleteMediaRecordsById,
  createAdsSetupByExcel,
} = adsSetupSlice.actions;

export default adsSetupSlice.reducer;
